.block-pageheader {
	display: none;
	color: $color-dark;
	font-family: $font-title;
	padding: 1rem 0 0.75rem 0;
}

.block-pageheader-title {
	font-size: 1.6rem;
	font-weight: 600;
	line-height: 1.8rem;
	margin: 0;
}