.block-button {
	background-color: $color-accent;
	border-radius: 3px;
	/* offset-x | offset-y | blur-radius | spread-radius | color */
	/* box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);*/
	color: $color-light;
	display: inline-block;
	font-family: $font-title;
	font-weight: 400;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.block-button:hover {
	background-color: lighten($color-accent, 15%);
	color: $color-light;
	text-decoration: none;
}

.block-button-small {
	font-size: 0.8rem;
	line-height: 0.8rem;
	padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

.block-button-medium {
	font-size: 1rem;
	line-height: 1rem;
	padding: 0.75rem 2rem 0.75rem 2rem;
}

.block-button-large {
	font-size: 1.4rem;
	line-height: 1.4rem;
	padding: 0.75rem 3rem 0.75rem 3rem;
}