#laundry-page {
    b, strong {
        font-family: $font-bold;
        font-weight: 700;
    }

    #laundry-header {
        background: linear-gradient(69deg, #47bbeb 15%, #46b7e8 30%, #2d5fa6 70%);
        padding: 100px 0;
        .container {
            max-width: 1630px;
            display: flex;
            justify-content: space-between;
            .body {
                width: 48%;
                h1 {
                    text-transform: uppercase;
                    font-size: 140px;
                    line-height: 120px;
                    color: $white;
                    font-family: $font-bold;
                    font-weight: 900;
                    span {
                        color: #25408f;
                        letter-spacing: 2px;
                    }
                }

                p {
                    font-size: 26px;
                    line-height: 36px;
                    color: $white;
                    font-family: $font-bold;
                    font-weight: 700;
                }
            }

            .image {
                width: 45%;
                img {
                    display: block;
                    max-height: 500px;
                    margin: 0 auto;
                }
            }
        }
    }

    .alert-block {
        padding: 60px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .image img {
            width: 170px;
            margin: 0 40px 0 83px;
        }

        .body {
            width: 100%;
            max-width: calc(100% - 270px);
            p {
                color: #25408f;
                font-size: 26px;
                line-height: 36px;
                font-family: $font-bold;
                font-weight: 700;
                margin: 0 0 15px;
                a {
                    color: #25408f;
                    text-decoration: underline;
                }
                &:last-child {
                    margin: 0;
                }
            }
        }
    }

    .fifty-fifty-list {
        background-color: #f7f7f7;
        border-radius: 20px;
        padding: 80px 0;
        display: flex;
        .half {
            position: relative;
            width: 100%;
            padding: 0 80px;
            h2 {
                color: #65bc47;    
                font-size: 52px;
                line-height: 54px;
                font-family: $font-bold;
                font-weight: 700;
                margin: 0 0 20px;
                text-transform: uppercase;
            }

            p {
                color: #4a4a4a;
                font-size: 30px;
                line-height: 38px;
            }

            ul {
                padding: 0 0 0 25px;
                li {
                    color: #4a4a4a;
                    font-size: 30px;
                    line-height: 38px;
                    margin: 0 0 15px;
                }
            }

            & + .half:before {
                content: "";
                width: 10px;
                height: 100%;
                border-radius: 5px;
                background-color: #65bc47;
                position: absolute;
                left: -5px;
                top: 0;
            }
        }
    }

    .tip-cards {
        padding: 60px;
        h2 {
            color: #25408f;    
            font-size: 52px;
            line-height: 54px;
            font-family: $font-bold;
            font-weight: 700;
            margin: 0 0 20px;
            text-transform: uppercase;
        }

        .wrapper {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 60px;
            padding: 60px 0;
            .tip {
                img {
                    margin: 0 0 40px;
                    max-height: 200px;
                }

                h3 {
                    font-size: 30px;
                    line-height: 36px;
                    font-family: $font-bold;
                    font-weight: 700;
                    color: #4a4a4a;
                    margin: 0 0 20px;
                }

                p {
                    font-size: 27px;
                    line-height: 34px;                
                    color: #4a4a4a;
                }

                ul {
                    margin: 0;
                    padding: 0 0 0 22px;
                    li {
                        color: #4a4a4a;
                        font-size: 27px;
                        line-height: 34px;
                        margin: 0 0 10px;
                    }
                }
            }
        }
    }
    
    .payment-block {
        background-color: #f7f7f7;
        border-radius: 20px;
        padding: 80px 60px;
        h2 {
            color: #65bc47;    
            font-size: 52px;
            line-height: 54px;
            font-family: $font-bold;
            font-weight: 700;
            margin: 0 0 20px;
            text-transform: uppercase;
        }

        > p {
            font-size: 27px;
            line-height: 34px;                
            color: #4a4a4a;
            max-width: 800px;
            margin: 0 0 60px;
        }

        .wrapper {
            display: flex;
            justify-content: space-around;
            .payment {
                width: 50%;
                max-width: 520px;
                display: flex;
                align-items: center;
                .image {
                    width: 100%;
                    max-width: 150px;
                }

                .body {
                    padding: 0 0 0 40px;
                    p {
                        font-size: 26px;
                        line-height: 36px;
                        color: #25408f;
                        font-family: $font-bold;
                        font-weight: 700;
                        margin: 0;
                    }
                }
            }
        }
    }

    .embeded-form {
        margin: 60px 0;
        > h2 {
            color: #25408f;
            font-size: 52px;
            line-height: 54px;
            font-family: $font-bold;
            font-weight: 700;
            margin: 0 0 40px;
            text-transform: uppercase;
        }
        .block-text-layout > .gform_wrapper {
            padding: 0;
            form {
                padding: 0;
                .gform_heading {
                    display: none;
                }

                .gform_body ul {
                    &.gfield_checkbox,
                    &.gfield_radio {
                        padding-left: 20px;
                    }

                    li.gfield:not(.select-wrapper) {
                        label {
                            width: 100%;
                            font-size: 20px;
                            line-height: 24px;
                            &:empty {
                                display: none;
                            }
                        }

                        .ginput_container {
                            label {
                                border: none;
                                font-family: $font-body;
                                font-size: 18px;
                                line-height: 20px;
                                cursor: pointer;
                            }

                            input[type="checkbox"],
                            input[type="radio"] {
                                display: none;
                            }

                            input[type="checkbox"] + label:before,
                            input[type="radio"] + label:before {
                                content: "";
                                width: 20px;
                                height: 20px;
                                display: inline-block;
                                vertical-align: middle;
                                margin: -4px 10px 0 0;
                                border: 1px solid #E0E2E4;
                            }

                            input[type="radio"] + label:before {
                                border-radius: 100%;
                            }

                            input[type="checkbox"]:checked + label:before,
                            input[type="radio"]:checked + label:before {
                                background-color: #25408f;
                            }
                        }

                        .ginput_container:not(.ginput_container_textarea) {
                            max-width: 100%;
                        }

                        &:not(.gpoll_field) label {
                            border: none;
                        }

                        &:nth-child(2) .ginput_container_text {
                            padding-left: 50px;
                            input {
                                width: 100%;
                                max-width: 100%;
                                min-width: 100%;
                                padding: 10px 15px!important;
                                margin: -20px 0 0 0;
                                color: #142A65;
                                border: 1px solid #E0E2E4;
                                border-radius: 0;
                                font-size: 14px;
                                line-height: 18px;
                                padding: 20px;
                                box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.09);
                            }

                            .ginput_counter {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .gform_confirmation_message {
            text-align: center;
            padding: 80px 0;
            color: #65bc47;
            font-size: 52px;
            line-height: 54px;
            font-family: $font-bold;
            font-weight: 700;
            margin: 0 0 20px;
            text-transform: uppercase;
        }
    }

    @media screen and (max-width: 1320px) {
        #laundry-header .container .body h1 {
            font-size: 100px;
            line-height: 100px;
        }

        .alert-block {
            .image img {
                margin: 0 40px 0 0;
            }

            .body {
                max-width: calc(100% - 190px);
            }
        }
    }

    @media screen and (max-width: 1320px) {
        .payment-block .wrapper {
            flex-flow: column;
            .payment {
                width: 100%;
                &:first-child {
                    margin: 0 0 30px;
                }
            }
        }
    }

    @media screen and (max-width: 990px) {
        #laundry-header {
            padding: 60px 0;
            .container {
                flex-flow: column;
                .body {
                    text-align: center;
                    width: 100%;
                    margin: 0 0 20px;
                }
                .image {
                    width: 100%;
                }
            }
        }

        .fifty-fifty-list {
            flex-flow: column;
            padding: 0;
            .half {
                padding: 40px;
                h2 {
                    font-size: 36px;
                    line-height: 38px;
                    br {
                        display: none;
                    }
                }

                p {
                    font-size: 20px;
                    line-height: 26px;
                }

                ul li {
                    font-size: 20px;
                    line-height: 26px;
                }

                & + .half:before {
                    width: calc(100% - 80px);
                    height: 10px;
                    top: -5px;
                    left: 40px;
                }
            }
        }

        .tip-cards {
            padding: 40px 0;
            h2 {
                font-size: 40px;
                line-height: 42px;
            }

            .wrapper {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 40px;
                padding: 40px 0;
                .tip {
                    img {
                        max-height: 140px;
                    }

                    h3 {
                        font-size: 24px;
                        line-height: 30px;
                    }

                    p, ul li {
                        font-size: 20px;
                        line-height: 26px;
                    }
                }
            }
        }

        .payment-block {
            padding: 40px;
            h2 {
                font-size: 40px;
                line-height: 42px;
            }

            > p {
                font-size: 20px;
                line-height: 26px;
            }
        }

        .embeded-form {
            > h2 {
                font-size: 40px;
                line-height: 42px;
            }

            .block-text-layout > .gform_wrapper form .gform_body ul li.gfield:not(.select-wrapper):nth-child(2) .ginput_container_text {
                padding: 0;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .alert-block {
            flex-flow: column;
            .image img {
                width: 130px;
                margin: 0 auto 40px auto;
            }

            .body {
                max-width: 100%;
                p {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }

        .payment-block .wrapper .payment {
            .image {
                max-width: 120px;
            }
            .body {
                padding: 0 0 0 30px;
                p {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }

        .embeded-form {
            .block-text-layout > .gform_wrapper form .gform_body ul li.gfield:not(.select-wrapper) {
                label {
                    font-size: 16px;
                    line-height: 22px;
                }
                .ginput_container label {
                    font-size: 16px;
                    line-height: 16px;
                    max-width: 100%;
                }
            }

            .gform_confirmation_message {
                font-size: 30px;
                line-height: 38px;
            }
        }
    }

    @media screen and (max-width: 560px) {
        #laundry-header .container {
            .body {
                h1 {
                    font-size: 60px;
                    line-height: 60px;
                }

                p {
                    font-size: 18px;
                    line-height: 28px;
                }
            }

            .image img {
                max-height: 200px;
            }
        }

        .fifty-fifty-list {
            .half {
                padding: 30px;
                h2 {
                    font-size: 32px;
                    line-height: 36px;
                }

                p {
                    font-size: 18px;
                    line-height: 24px;
                }

                ul li {
                    font-size: 18px;
                    line-height: 24px;
                }

                & + .half:before {
                    width: calc(100% - 60px);
                    left: 30px;
                }
            }
        }

        .tip-cards {
            padding: 20px 0;
            h2 {
                font-size: 30px;
                line-height: 32px;
            }

            .wrapper {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 20px;
                padding: 20px 0;
                .tip {
                    text-align: center;
                    img {
                        margin: 0 auto 20px;
                        display: block;
                    }

                    h3 {
                        font-size: 20px;
                        line-height: 28px;
                    }

                    p, ul li {
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
            }
        }

        .payment-block {
            h2 {
                font-size: 32px;
                line-height: 36px;            
            }

            p {
                font-size: 18px;
                line-height: 24px;
                margin: 0 0 20px;
            }

            .wrapper .payment {
                .image {
                    max-width: 100px;
                }
                .body {
                    padding: 0 0 0 20px;
                    p {
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
            }
        }

        .embeded-form > h2 {
            font-size: 30px;
            line-height: 32px;
        }
    }

    @media screen and (max-width: 480px) {
        .payment-block .wrapper .payment {
            flex-flow: column;
            .image {
                max-width: 100px;
                margin: 0 0 20px;
            }
            .body {
                padding: 0;
                p {
                    font-size: 18px;
                    line-height: 24px;
                    br {
                        display: none;
                    }
                }
            }
        }
        .embeded-form .block-text-layout > .gform_wrapper form .gform_body ul {
            &.gfield_checkbox, 
            &.gfield_radio {
                padding-left: 0;
            }
        }
    }

    @media screen and (max-width: 340px) {
        #laundry-header .container .body h1 {
            font-size: 50px;
            line-height: 50px;
        }
    }
}

body.page-template-laundry .block-siteheader {
    padding: 25px 0 20px;
    .block-siteheader-menutoggle {
        display: none!important;
    }
    @media (max-width: 990px) {
        padding: 16px 0;
    }
}