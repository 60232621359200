#top-sort {
    // display: none;
    display: flex;
    min-height: 100vh;
    padding: 37px;
    align-items: center;
    justify-content: space-between;
    padding-left: 300px;
    aside {
        @media screen and (min-width: 769px) {
            position: fixed;
            top: 37px;
            width: 202px;
            max-height: calc(100vh - 74px);
            z-index: 1;
            transform: translateX(-300px);
        }

        .logo {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .contact {
                display: none;
                a:before {
                    display: inline-block;
                    vertical-align: middle;
                    content: "";
                    width: 20px;
                    height: 20px;
                    margin: -2px 8px 0 0;
                    background-color: #4C9BC6;
                    border-radius: 10px;
                    background-image: url('templates/buyer/images/phone.svg');
                    background-repeat: no-repeat;
                    background-size: 10px;
                    background-position: center;
                }
            }
        }

        .page-title {
            width: 100%;
            margin: 28px 0 0 0;
            .back {
                display: flex;
                align-items: center;
                margin: 0 0 21px;
                font-size: 16px;
                line-height: 24px;
                cursor: pointer;
                svg {
                    width: 17px;
                    margin: 0 9px 0 0;
                    path {
                        fill: $BE-blue;
                    }
                }
            }

            h1 {
                font-size: 36px;
                line-height: 43px;
                color: $blue;
                margin: 0;
                letter-spacing: -0.68px;
            }

            @media screen and (min-width: 961px) {
                width: 202px;
            }
        }

        .progress-bar {
            margin: 20px 0 0 0;
            p {
                text-transform: uppercase;
                color: $light-blue;
                letter-spacing: 1.2px;
                font-size: 12px;
                line-height: 15px;
                margin: 0 0 8px;
                font-family: $font-bold;
            }

            .steps {
                display: flex;
                .step {
                    width: 46px;
                    height: 9px;
                    background-color: #D2D3DE;
                    &:not(:first-child) {
                        margin: 0 0 0 4px;
                    }
                }
            }
        }

        .step-description {
            margin: 32px 0 0 0;
            p {
                font-size: 16px;
                line-height: 24px;
                color: $BE-blue;
            }
        }
    }

    section.main-content {
        display: none;
        position: relative;
        z-index: 1;
        max-width: 45vw;
        .step-number {
            display: table;
            padding: 16px;
            font-size: 24px;
            line-height: 29px;
            color: $BE-blue;
            font-family: $font-bold;
            background-color: $white;
            box-shadow: -1px 16px 26px 0 rgba(76, 155, 198, 0.22);
        }

        .dependency-container {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            .question-wrapper {
                max-width: 100%;
                position: relative;
                margin: 16px 0 0 0;
                padding: 16px;
                @include translucent;
                transition-property: margin-top, opacity, visibility;
                transition-timing-function: ease, linear, linear;
                transition-duration: 0.4s, 0.2s, 0.2s;
                h3 {
                    font-size: 20px;
                    line-height: 24px;
                    margin: 0 0 16px;
                    letter-spacing: -0.38px;
                    color: $BE-blue;
                    font-family: $font-body;
                }

                .answers {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    grid-gap: 16px;
                    .answer-button {
                        background-color: $white;
                        color: $BE-blue;
                        padding: 16px;
                        text-align: center;
                        font-size: 16px;
                        line-height: 19px;
                        font-family: $font-medium;
                        box-shadow: 0 3px 60px 0 rgba(78, 127, 154, 0.29);
                        white-space: nowrap;
                        cursor: pointer;
                        min-width: 252px;
                        &:nth-child(2n) {
                            margin-right: 0;
                        }

                        &:hover,
                        &.on {
                            background-color: $green;
                            color: $white;
                        }
                    }
                }

                &:first-child {
                    position: relative;
                    z-index: 5;
                }

                &:not(:first-child):not(.visible) {
                    visibility: hidden;
                    opacity: 0;
                }

                &:nth-child(2):not(.visible) {
                    margin-top: -140px;
                }

                &:nth-child(3),
                &.number-type:nth-child(3) {
                    margin-top: -140px;
                }

                &:nth-child(4) {
                    margin-top: -180px;
                }

                &:nth-child(5) {
                    margin-top: -257px!important;
                }

                &.number-type {
                    width: 100%;
                    margin-top: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    h3 {
                        max-width: 206px;
                        margin: 0 80px 0 0;
                    }
                }

                &.error {
                    input[type="number"] {
                        border-color: red!important;
                    }

                    &:before {
                        content: "";
                        width: 8px;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background: red;
                    }
                }
            }

            &[type="1"] .question-wrapper:nth-child(2) {
                margin-top: 16px!important;
                opacity: 1!important;
                visibility: visible!important;
                transition-delay: 0s, 0.1s, 0.1s;
            }

            &[type="2"] .question-wrapper:nth-child(3) {
                margin-top: 16px!important;
                opacity: 1;
                visibility: visible;
            }

            &[type="3"] .question-wrapper:nth-child(4) {
                margin-top: 16px!important;
                opacity: 1;
                visibility: visible;
            }

            &[type="4"] div.question-wrapper:not(.visible):nth-child(5) {
                margin-top: 16px!important;
                opacity: 1;
                visibility: visible;
            }

            &[type="123"] {
                .question-wrapper:nth-child(2),
                .question-wrapper:nth-child(3),
                .question-wrapper:nth-child(4) {
                    margin-top: 16px!important;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        &[step="1"] .dependency-container .question-wrapper:nth-child(2):not(.visible) {
            margin-top: -324px;
        }
    }

    .cta-area {
        display: flex;
        flex-flow: column;
        align-items: center;
        position: relative;
        z-index: 2;
        .edit-area {
            padding: 16px 39px 16px 16px;
            @include translucent;
            margin: 0 0 20px;
            max-width: 380px;
            .set {
                display: flex;
                align-items: center;
                .info {
                    width: 100%;
                    padding-right: 16px;
                    p {
                        font-size: 16px;
                        line-height: 24px;
                        margin: 0;
                        color: $BE-blue;
                        letter-spacing: -0.53px;
                        strong {
                            display: inline-block;
                        }
                    }
                }

                .goToPage {
                    width: 14px;
                    height: 14px;
                    background-color: $BE-blue;
                    border-radius: 14px;
                    cursor: pointer;
                    flex-shrink: 0;
                    background-image: url('templates/buyer/images/pen.svg');
                    background-repeat: no-repeat;
                    background-size: 8px;
                    background-position: center;
                }

                & + .set {
                    margin: 18px 0 0 0;
                }
            }
        }

        .to-service-rooms {
            display: none;
            &.always-on {
                display: block;
                margin-top: 16px;
            }
        }

        &.path-end {
            button {
                display: none;
            }

            .to-service-rooms {
                display: block;
            }
        }
    }

    &[step="1"] {
        aside {
            .page-title {
                margin: 73px 0 0 0;
                .back {
                    display: none;
                }
            }

            .progress-bar .steps .step:first-child {
                background-color: $BE-blue;
            }
        }

        .main-content[step="1"] {
            display: block;
        }

        .cta-area {
            .edit-area {
                display: none;
            }
        }
    }

    &[step="2"] {
        aside .progress-bar .steps .step:nth-child(-n + 2) {
            background-color: $BE-blue;
        }

        .main-content[step="2"] {
            display: block;
        }

        .cta-area .set:nth-child(2) {
            display: none;
        }
    }

    &[step="3"] {
        aside .progress-bar .steps .step:nth-child(-n + 3) {
            background-color: $BE-blue!important;
        }

        .main-content[step="3"] {
            display: block;
        }

        .cta-area {
            .next {
                display: none;
            }

            a {
                display: block;
            }
        }
    }

    @media screen and (max-width: 1440px) {
        aside {
            left: 0;
            padding-left: 37px;
            transform: none;
        }
    }

    @media screen and (max-width: 1380px) {
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        section.main-content {
            max-width: 100%;
        }

        .cta-area {
            align-items: flex-start;
            margin: 36px 0 0 0;
            .edit-area {
                margin: 36px 0 20px 0;
            }
        }

        &[step="2"] {
            section.main-content .dependency-container .question-wrapper:nth-child(2):not(.visible) {
                margin-top: -135px;
            }
            section.main-content .dependency-container .question-wrapper:nth-child(4) {
                margin-top: -190px;
            }
            section.main-content .dependency-container .question-wrapper:nth-child(5):not(.visible) {
                margin-top: -135px!important;
            }
        }
    }

    @media screen and (max-width: 960px) {
        justify-content: flex-start;
        padding-left: 37px;
        padding-top: 28px;
        aside {
            width: 100%;
            position: relative;
            padding: 0;
            top: 0;
            .logo .contact {
                display: block;
            }

            .page-title {
                margin: 44px 0 0 0;
            }
        }

        section.main-content {
            margin: 36px 0 0 0;
        }
    }

    @media screen and (max-width: 690px) {
        section.main-content .dependency-container .question-wrapper {
            .answers {
                grid-template-columns: repeat(1, 100%);
            }

            &:nth-child(2):not(.visible) {
                margin-top: -615px;
            }
            
            &:nth-child(3) {
                margin-top: -190px;
            }
        }

        &[step="2"] section.main-content .dependency-container div.question-wrapper:nth-child(4) {
            margin-top: -319px;
        }
    }

    @media screen and (max-width: 480px) {
        padding: 28px 20px 60px;
        .logo a img {
            max-width: 140px;
        }

        section.main-content .dependency-container .question-wrapper {
            width: 100%;
            &.number-type {
                flex-flow: column;
                h3 {
                    max-width: 100%;
                    margin: 0 0 16px;
                }
            }
        }

        .cta-area {
            width: 100%;
            .edit-area {
                max-width: 100%;
                width: 100%;
                padding: 22px 16px 30px;
                .set {
                    flex-flow: column;
                    .info {
                        margin: 0 0 28px;
                    }

                    .goToPage {
                        display: flex;
                        align-items: center;
                        transform: translateX(-24px);
                        &:after {
                            content: "Edit";
                            font-size: 16px;
                            font-family: $font-bold;
                            color: $BE-blue;
                            text-decoration: underline;
                            margin: 0 0 0 20px;
                        }
                    }

                    & + .set {
                        margin: 36px 0 0 0;
                    }
                }
            }
        }

        &[step="2"] section.main-content .dependency-container div.question-wrapper:nth-child(4) {
            margin-top: -424px;
        }
    }


    @media screen and (max-width: 392px) {
        aside .logo {
            flex-flow: column;
            .contact {
                margin: 16px 0 0 0;
            }
        }

        section.main-content .dependency-container .question-wrapper .answers .answer-button {
            font-size: 12px;
            min-width: unset;
        }

        .cta-area {
            width: 100%;
        }
    }
}