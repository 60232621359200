#be-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: all 0.2s ease;
    .be-modal-overlay {
        width: 100%;
        height: 100%;
        backdrop-filter: blur(2px);
    }

    .be-modal-wrapper {
        position: absolute;
        width: 96vw;
        height: auto;
        max-width: 784px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: $white;
        padding: 64px 50px 50px 50px;
        box-shadow: 0 3px 46px 0 rgba(10, 11, 72, 0.28);
        > * {
            max-width: 529px;
            margin: 0 auto;
        }

        h4 {
            margin: 0 0 22px;
            line-height: 29px;
        }

        p {
            margin: 0 0 24px;
            font-size: 18px;
            line-height: 23px;
            color: $blue;
            text-rendering: geometricprecision;
        }

        strong {
            display: block;
            font-size: 18px;
            line-height: 21px;
            width: 100%;
            text-align: center;
        }

        .cta-area {
            display: flex;
            justify-content: center;
            margin: 37px 0 0 0;
            text-align: center;
            a.button {
                min-width: unset;
                & + a.button {
                    margin: 0 0 0 20px;
                }
            }

            & + p {
                margin: 45px 0 0 0;
                font-size: 14px;
                line-height: 16px;
                color: #142A65;
                text-align: center;
            }
        }

        .close-be-modal {
            position: absolute;
            right: 0;
            top: 0;
            padding: 19px 40px 19px 19px;
            cursor: pointer;
            font-size: 14px;
            line-height: 16px;
            font-family: $font-medium;
            color: $blue;
            &:before,
            &:after {
                content: "";
                width: 15px;
                height: 2px;
                background-color: $blue;
                position: absolute;
                right: 19px;
                top: 25px;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }

        .content {
            display: none;
        }
    }

    &.on {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    @media screen and (max-width: 480px) {
        .be-modal-wrapper {
            padding: 40px 20px 20px 20px;
            .cta-area {
                flex-flow: column;
                a.button + a.button {
                    margin: 20px 0 0 0;
                }
            }
        }
    }
}