/**
 * Place project-specific branding in this file.
 */
$container-width: 1170px;
$mobile:          480px;
$tablet:          441px;
$desktop:         769px;
$wide:            1080px;
$laptop:          1440px;
$fhd:             1900px;

/**
 * Text and fonts
 */
 /* Fonts */
//  Gotham
@font-face {
    font-family: 'GothamBook';
    src: url('global/fonts/Gotham/Gotham-Book.eot');
    src: url('global/fonts/Gotham/Gotham-Book.eot?#iefix') format('embedded-opentype'),
         url('global/fonts/Gotham/Gotham-Book.woff2') format('woff2'),
         url('global/fonts/Gotham/Gotham-Book.woff') format('woff'),
         url('global/fonts/Gotham/Gotham-Book.ttf')  format('truetype'),
         url('global/fonts/Gotham/Gotham-Book.svg#svgGothamBook') format('svg');
}

@font-face {
    font-family: 'GothamLight';
    src: url('global/fonts/Gotham/Gotham-Light.eot');
    src: url('global/fonts/Gotham/Gotham-Light.eot?#iefix') format('embedded-opentype'),
         url('global/fonts/Gotham/Gotham-Light.woff2') format('woff2'),
         url('global/fonts/Gotham/Gotham-Light.woff') format('woff'),
         url('global/fonts/Gotham/Gotham-Light.ttf')  format('truetype'),
         url('global/fonts/Gotham/Gotham-Light.svg#svgGothamLight') format('svg');
}

@font-face {
    font-family: 'GothamMedium';
    src: url('global/fonts/Gotham/Gotham-Medium.eot');
    src: url('global/fonts/Gotham/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
         url('global/fonts/Gotham/Gotham-Medium.woff2') format('woff2'),
         url('global/fonts/Gotham/Gotham-Medium.woff') format('woff'),
         url('global/fonts/Gotham/Gotham-Medium.ttf')  format('truetype'),
         url('global/fonts/Gotham/Gotham-Medium.svg#svgGothamMedium') format('svg');
}

@font-face {
    font-family: 'GothamBold';
    src: url('global/fonts/Gotham/Gotham-Bold.eot');
    src: url('global/fonts/Gotham/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
         url('global/fonts/Gotham/Gotham-Bold.woff2') format('woff2'),
         url('global/fonts/Gotham/Gotham-Bold.woff') format('woff'),
         url('global/fonts/Gotham/Gotham-Bold.ttf')  format('truetype'),
         url('global/fonts/Gotham/Gotham-Bold.svg#svgGothamBold') format('svg');
}

// Montserrat
@font-face {
    font-family: 'MontserratRegular';
    src: url('global/fonts/Montserrat/Montserrat-Regular.eot');
    src: url('global/fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
         url('global/fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
         url('global/fonts/Montserrat/Montserrat-Regular.woff') format('woff'),
         url('global/fonts/Montserrat/Montserrat-Regular.ttf')  format('truetype');
}

@font-face {
    font-family: 'MontserratLight';
    src: url('global/fonts/Montserrat/Montserrat-Light.eot');
    src: url('global/fonts/Montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
         url('global/fonts/Montserrat/Montserrat-Light.woff2') format('woff2'),
         url('global/fonts/Montserrat/Montserrat-Light.woff') format('woff'),
         url('global/fonts/Montserrat/Montserrat-Light.ttf')  format('truetype');
}

@font-face {
    font-family: 'MontserratMedium';
    src: url('global/fonts/Montserrat/Montserrat-Medium.eot');
    src: url('global/fonts/Montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
         url('global/fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
         url('global/fonts/Montserrat/Montserrat-Medium.woff') format('woff'),
         url('global/fonts/Montserrat/Montserrat-Medium.ttf')  format('truetype');
}

@font-face {
    font-family: 'MontserratBold';
    src: url('global/fonts/Montserrat/Montserrat-Bold.eot');
    src: url('global/fonts/Montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
         url('global/fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
         url('global/fonts/Montserrat/Montserrat-Bold.woff') format('woff'),
         url('global/fonts/Montserrat/Montserrat-Bold.ttf')  format('truetype'),
         url('global/fonts/Montserrat/Montserrat-Bold.svg#svgMontserratBold') format('svg');
}

$font-gotham:       'GothamBook', 'Open Sans', sans-serif;
$font-gotham-light: 'GothamLight', 'Open Sans', sans-serif;
$font-gotham-medium:'GothamMedium', 'Open Sans', sans-serif;
$font-gotham-bold:  'GothamBold', 'Open Sans', sans-serif;

$font-montserrat:   'MontserratRegular', 'Open Sans', sans-serif;
$font-montserrat-light: 'MontserratLight', 'Open Sans', sans-serif;
$font-montserrat-medium:'MontserratMedium', 'Open Sans', sans-serif;
$font-montserrat-bold:  'MontserratBold', 'Open Sans', sans-serif;


$font-title:        $font-montserrat;
$font-body:         $font-montserrat;

$font-light:        $font-montserrat-light;
$font-medium:       $font-montserrat-medium;
$font-bold:         $font-montserrat-bold;

/**
 * Colors
 */
$white:             #FFF;
$black:             #000;

$color-primary:     #cccccc;
$color-secondary:   #f3f3f3;
$color-accent:      #666666;
$color-grey:        #cccccc;
$color-dark:        #61666A;
$color-light:       #F9FBFC;
$background-light-gray: #EFF0F1;
$welcome-block-grey:#F7F7F7;
$dark-text-grey:    #474C50;
$medium-grey:       #A8AFB5;
$border:            #D4DADD;
$help-grey:         #f9fafc;
$faq-border:        #C4C4C4;

$light-grey:        rgba(0, 0, 0, 0.15);
$welcome-light-grey:#D8D8D8;
$welcome-grey:      #4A4A4A;
$text-grey:         #4E4A4F;
$footer-grey:       #385464;
$block-grey:        #20262F;
$cool-grey:         #A3B6B9;
$cta-blue:          #DDF2FB;
$light-blue:        #23A0CD;
$hover-blue:        #23A0CD;
$welcome-blue:      #23A0CD;
$welcome-blue-dark: #103870;
$mobile-blue:       #EAF6FC;
$BE-blue:           #0A0B48;
$lighter-blue:      #ddf2fb;
$blue:              #142A65;
$icon-blue:         #3E5880;
$soft-navy:         #485786;
$navy:              #222E65;
$welcome-footer:    #25408F;
$deep-navy:         #222E65;
$pool-blue:         #23A0CD;

$faded-green:       #EEF7EA;
$green:             #44A324;
$welcome-green:     #44A324;
$help-green:        #44A324;

$transparent:       rgba(255, 255, 255, 0);