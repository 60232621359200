#build-quote {
    // display: flex;
    display: none;
    min-height: 100vh;
    padding: 37px;
    align-items: center;
    justify-content: space-between;
    padding-left: 327px;
    padding-top: 140px;

    .back {
        display: flex;
        align-items: center;
        margin: 0 0 21px;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        svg {
            width: 17px;
            margin: 0 9px 0 0;
            path {
                fill: $BE-blue;
            }
        }

        span.trends {
            display: none;
        }
    }

    aside {
        @media screen and (min-width: 991px) {
            position: fixed;
            top: 37px;
            width: 311px;
            max-height: calc(100vh - 74px);
            z-index: 1;
            transform: translateX(-300px);
        }

        .logo {
            display: flex;
            align-items: center;
            h2 {
                margin: 0 0 0 15px;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                font-family: $font-bold;
                color: $BE-blue;
                white-space: nowrap;
            }
        }

        .page-title {
            margin: 72px 0 0 0;

            h2 {
                font-size: 36px;
                line-height: 43px;
                color: $blue;
                margin: 0;
                letter-spacing: -0.68px;
            }
        }

        .progress-bar {
            margin: 20px 0 0 0;
            p {
                text-transform: uppercase;
                color: $light-blue;
                letter-spacing: 1.2px;
                font-size: 12px;
                line-height: 15px;
                margin: 0 0 8px;
                font-family: $font-bold;
            }

            .steps {
                display: flex;
                .step {
                    width: 46px;
                    height: 9px;
                    background-color: #D2D3DE;
                    &:not(:first-child) {
                        margin: 0 0 0 4px;
                    }
                }
            }
        }
    }

    .community-flow,
    .air-flow,
    .commercial-flow,
    .in-home-flow {
        width: 100%;
        display: none;
    }

    .main-content {
        width: 100%;
        display: none;
        flex-flow: column;
        align-items: flex-start;
        flex-shrink: 0;
        .step-title {
            display: table;
            padding: 16px;
            font-size: 24px;
            line-height: 29px;
            color: $BE-blue;
            font-family: $font-bold;
            background-color: $white;
            box-shadow: -1px 16px 26px 0 rgba(76, 155, 198, 0.22);
            margin: 0 0 24px;
            &.large-machine-number {
                display: none;
                &.active {
                    display: block;
                    & + .step-title {
                        display: none;
                    }
                }
            }
        }

        .wrapper {
            width: 100%;
            max-width: 48vw;
            @include translucent;
            padding: 25px;
            margin: 0 0 14px;
            h3 {
                font-size: 20px;
                line-height: 24px;
                margin: 0 0 16px;
                letter-spacing: -0.38px;
                color: $BE-blue;
                font-family: $font-body;
            }

            p {
                font-size: 20px;
                line-height: 22px;
                letter-spacing: -0.66px;
                color: $BE-blue;
                margin: 0 0 30px;
                &.act-as {
                    padding-right: 50px;
                }

                span:not(.selected-term) {
                    font-family: $font-bold;
                }

                &:last-child {
                    margin: 0;
                }
            }

            .linked p {
                display: inline-block;
                margin: 0;
            }

            .quote-edit {
                position: absolute;
                top: 25px;
                right: 25px;
                color: $BE-blue;
                font-size: 10px;
                line-height: 24px;
                font-family: $font-bold;
                &:before {
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    margin: -2px 2px 0 0;
                    width: 14px;
                    height: 14px;
                    background-color: $BE-blue;
                    border-radius: 14px;
                    background-image: url(templates/buyer/images/pen.svg);
                    background-repeat: no-repeat;
                    background-size: 8px;
                    background-position: center;
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }


        .gform_wrapper {
            margin: 0;
            form {
                width: 100%;
                .gfield {
                    margin: 0 0 10px;
                    @media screen and (min-width: 641px) {
                        &.gfield--width-half,
                        &.half {
                            grid-column: span 6;
                        }
                        &.quorter {
                            grid-column: span 3;
                        }
                    }
                }

                h3.gsection_title {
                    text-transform: uppercase;
                    font-size: 18px;
                    line-height: 20px;
                    margin: 10px 0 0 0;
                }
                
                // altered style for select
                .ginput_container_select {
                    position: relative;
                }
                .select2 {
                    min-height: unset;
                    background: $white;
                    border-radius: 4px;
                    .select2-selection--single {
                        .select2-selection__rendered {
                            font-size: 16px;
                            line-height: 40px;
                            min-height: 40px;
                            padding: 0 16px;
                        }

                        .select2-selection__arrow {
                            background-color: $white;
                            width: 34px;
                            b {
                                background-image: none;
                                width: 0;
                                height: 0;
                                border-top: 8px solid $navy;
                                border-right: 6px solid $white;
                                border-left: 6px solid $white;
                            }
                        }
                    }
                }
            }
        }

        .dependency-container {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            width: 100%;
            z-index: 1;
            .question-wrapper {
                transition-property: margin-top, opacity, visibility;
                transition-timing-function: ease, linear, linear;
                transition-duration: 0.4s, 0.2s, 0.2s;
                &:not(:first-child):not(.visible) {
                    visibility: hidden;
                    opacity: 0;
                    margin-top: -123px;
                }

                &.machine-sizer {
                    width: 100%;
                    max-width: 862px;
                    padding: 25px 32px 19px 13px;
                    display: grid;
                    grid-row-gap: 31px;
                    grid-column-gap: 5px;
                    grid-template-columns: auto 285px;
                    > h6 {
                        grid-column: 1 / span 2;
                        width: 100%;
                        @include font(18px, 21px, $BE-blue, 0, $font-bold);
                    }

                    .inputs {
                        display: flex;
                        flex-flow: column;
                        justify-content: center;
                        .question-wrapper {
                            margin: 0;
                            & + .question-wrapper {
                                margin: 24px 0 0 0;
                            }
                        }
                    }

                    .estimation {
                        display: flex;
                        justify-content: center;
                        flex-flow: column;
                        // box-shadow: 0 16px 26px rgba(76, 155, 198, 0.22);
                        // backdrop-filter: blur(7px);
                        // border-radius: 20px;
                        padding: 20px 45px;
                        height: 320px;
                        background-image: url(templates/buyer/images/washing-machine-image.png);
                        background-size: contain;
                        background-position: center top;
                        background-repeat: no-repeat;
                        p {
                            @include font(18px, 24px, $BE-blue, 28px, $font-body);
                            &.estimated-number {
                                font-size: 24px;
                                margin: 0;
                            }
                        }
                    }

                    &:not(:first-child):not(.visible) {
                        margin-top: -376px;
                    }
                }
            }

            &[type="1"] .question-wrapper:nth-child(2) {
                margin-top: 16px!important;
                opacity: 1;
                visibility: visible;
            }

            &[type="2"] .question-wrapper:nth-child(3) {
                margin-top: 16px!important;
                opacity: 1;
                visibility: visible;
            }

            &[type="3"] .question-wrapper:nth-child(4) {
                margin-top: 16px!important;
                opacity: 1;
                visibility: visible;
            }

            &[type="4"] .question-wrapper:nth-child(5) {
                margin-top: 16px!important;
                opacity: 1;
                visibility: visible;
            }
            
            &[type="13"] {
                .question-wrapper:nth-child(2),
                .question-wrapper:nth-child(4) {
                    margin-top: 16px!important;
                    opacity: 1;
                    visibility: visible;
                }
            }

            &[type="12"] {
                .question-wrapper:nth-child(2),
                .question-wrapper:nth-child(3) {
                    margin-top: 16px!important;
                    opacity: 1;
                    visibility: visible;
                }
            }
            
            &[type="123"] {
                .question-wrapper:nth-child(2),
                .question-wrapper:nth-child(3),
                .question-wrapper:nth-child(4) {
                    margin-top: 16px!important;
                    opacity: 1;
                    visibility: visible;
                }
            }

            &[type="23"] {
                .question-wrapper:nth-child(3),
                .question-wrapper:nth-child(4) {
                    margin-top: 16px!important;
                    opacity: 1;
                    visibility: visible;
                }
            }

            &[subtype="3"] {
                .question-wrapper:nth-child(3) {
                    margin-top: 16px!important;
                    opacity: 1;
                    visibility: visible;
                }
            }

            & + .dependency-container {
                margin: 16px 0 0 0;
            }

            &.large-machine-number {
                display: none;
                h3 {
                    @include translucent;
                    padding: 30px;
                    margin: 0 0 40px;
                }

                &.active {
                    display: block;
                    & ~ * {
                        display: none;
                    }
                }
            }
        }

        .question-wrapper {
            position: relative;
            @include translucent;
            padding: 16px;

            h3 {
                font-size: 20px;
                line-height: 24px;
                margin: 0 0 16px;
                letter-spacing: -0.38px;
                color: $BE-blue;
                font-family: $font-body;
            }

            .answers {
                display: grid;
                grid-template-columns: repeat(2, auto);
                grid-gap: 16px;
                .answer-button {
                    background-color: $white;
                    color: $BE-blue;
                    padding: 16px;
                    font-size: 16px;
                    line-height: 19px;
                    font-family: $font-medium;
                    box-shadow: 0 3px 60px 0 rgba(78, 127, 154, 0.29);
                    white-space: nowrap;
                    cursor: pointer;
                    min-width: 252px;
                    p {
                        white-space: normal;
                        letter-spacing: 0;
                        margin-top: 7px;
                        @include font(11px, 17px, $BE-blue, 0, $font-body);
                    }

                    &.large {
                        max-width: 280px;
                    }

                    &:not(.large) {
                        text-align: center;
                    }

                    &:hover,
                    &.on {
                        background-color: $green;
                        color: $white;
                        p {
                            color: $white;
                        }
                    }
                    
                    &.checkbox {
                        text-align: left;
                        font-family: $font-body;
                        &:before {
                            content: "";
                            display: inline-block;
                            vertical-align: middle;
                            width: 18px;
                            height: 18px;
                            border-radius: 4px;
                            border: 2px solid #4C9BC6;
                            margin: -2px 15px 0 0;
                            background-repeat: no-repeat;
                            background-size: 14px;
                            background-position: center;
                        }
                        
                        &.on,
                        &:hover {
                            background-color: $white;
                            color: $BE-blue;
                            font-family: $font-bold;
                        }

                        &.on:before {
                            background-image: url(templates/buyer/images/check.svg);
                        }
                    }
                }
            }
            
            &.number-type {
                position: relative;
                width: 100%;
                max-width: 554px;
                margin-top: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                h3 {
                    max-width: 206px;
                    margin: 0 80px 0 0;
                }

                .state-popup {
                    position: absolute;
                    left: 100%;
                    top: 50%;
                    transform: translate(24px, -50%);
                    background: $white;
                    padding: 16px;
                    @include font(16px, 19px, $BE-blue, 0, $font-bold);
                    box-shadow: -1px 16px 26px rgba(76, 155, 198, 0.22);
                    white-space: nowrap;
                    &:empty {
                        display: none;
                    }
                }

                &.large-text {
                    max-width: 597px;
                    flex-flow: column;
                    h3 {
                        max-width: unset;
                        margin: 0;
                    }

                    input[type="number"] {
                        margin: 20px auto;
                    }
                }
            }

            &.select-type {
                max-width: 554px;
                span.select2 {
                    width: 100%!important;
                    .select2-selection--single {
                        background: rgba(255, 255, 255, 0);
                        border: none;
                        border-bottom: 1px solid $BE-blue;
                        border-radius: 0;
                        height: auto;
                        .select2-selection__rendered {
                            font-size: 20px;
                            line-height: 60px;
                            color: $BE-blue;
                        }

                        .select2-selection__arrow {
                            height: calc(100% - 2px);
                            width: 20px;
                            top: 1px;
                            right: 0;
                            b {
                                width: 16px;
                                height: 16px;
                                background-size: auto 10px;
                                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAFaADAAQAAAABAAAAFQAAAAAr0zA0AAABbUlEQVQ4EWNmIAIIqaeEcIoa7eQQMzrz4/W5R4S0MBFSAJJnZGRsZWD4L8P4j6GDGPVEGcrA+J8JaDIDIwMDUeqJUkSM65DVjBqKHBrUYdMoTKXSuIQ0kndSw42CGinbGRhCmZmE+f8fZmRkchPRTH1NicHCmql/gZnEQ1CLbzcTMEnXARP1F2DKFiHXYIiBDECzGD4y/WHOYnpzfdZWYEaMYPgPMVhYM+UNKS6GGfj/P8NHhr+MFm9vzboBjihkg4FeECbWYGwGghwEj31SDQZa/A9YHDAhuxDmQ7ihIAFiDYYYyMiIzUCQOcCwxQQimmnewKJuBVCW5z8DwzuG///fAWkVxv8MJxiZGC1AOnAZCJJjBhHo4Nubs7e5RE0uAcX9gF7k/8/AyAI0kB0U3kCLWPEZCDILq0thliC7GCZGyECQOqwuhRmA7GKg9WzEGAjSi9elMMP5VdKUmVn+7WL6/8/gzc15n2HiuGgAYIibQzmXn0EAAAAASUVORK5CYII=');
                                background-repeat: no-repeat;
                                background-position: center;
                                margin-left: 0;
                                margin-top: 0;
                                transform: translate(-50%, -50%);
                                border: none;
                            }
                        }
                    }

                    & + .select2-container {
                        left: auto !important;
                        right: 0;
                        width: 100%;
                        .select2-dropdown {
                            width: 100%!important;
                            border: none;
                            box-shadow: 0 9px 17px 0 rgba(0, 0, 0, 0.11);
                            .select2-search {
                                // display: block!important;
                                input {
                                    padding: 15px;
                                    border-radius: 0;
                                    font-size: 18px;
                                    line-height: 20px;
                                    &:focus {
                                        outline: none;
                                        box-shadow: none;
                                    }
                                }
                            }

                            .select2-results ul {
                                max-height: 310px;
                                display: flex;
                                flex-wrap: wrap;
                                li {
                                    margin: 0;
                                    padding: 10px;
                                    color: #707070;
                                    font-size: 12px;
                                    line-height: 44px;
                                    padding: 0 20px;
                                    width: 100%;
                                    box-sizing: border-box;
                                    text-align: left;
                                    &.select2-results__option--highlighted {
                                        background-color: $hover-blue;
                                        color: $white;
                                        font-family: $font-medium;
                                    }
                                }
                            }                
                        }

                        @media screen and (max-width: 990px) {
                            width: 100%;
                            right: 0;
                        }
                    }
                }
            }

            &.three-column .answers {
                grid-template-columns: repeat(3, auto);
            }

            &.error {
                input[type="number"] {
                    border-color: red!important;
                    color: red!important;
                }

                &:before {
                    content: "";
                    width: 8px;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: red;
                }
            }
        }

        button.next {
            margin: 24px 0 0 0;
        }

        &[step="5"] {
            flex-flow: row;
            justify-content: space-between;
            align-items: flex-start;
            .back {
                position: absolute;
                left: 40px;
                top: 120px;
            }

            .wrapper {
                display: none;
                @include translucent;
                width: 100%;
                max-width: calc(100% - 490px);
                // margin-top: 25px;
                margin-top: 70px;
                padding: 16px 42px 44px;
                > h2 {
                    margin: 10px 0 20px;
                }
                header {
                    border-top: 1px solid $BE-blue;
                    padding-top: 8px;
                    margin: 0 0 40px;
                    h4 {
                        @include font(24px, 24px, $BE-blue, 0, $font-bold);
                    }

                    p {
                        @include font(16px, 19px, $BE-blue, 12px, $font-body);
                        span {
                            font-family: $font-bold;
                            display: inline-block;
                            margin: 0 16px 0 0;
                            width: 93px;
                        }

                        &:first-child {
                            text-transform: uppercase;
                            span {
                                text-transform: none;
                            }
                        }
                    }
                }

                section {
                    border-top: 1px solid $BE-blue;
                    padding: 36px 0 0 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: baseline;
                    .title {
                        width: 202px;
                        padding-right: 16px;
                        h3 {
                            @include font(12px, 15px, #4C9BC6, 0, $font-medium);
                            text-transform: uppercase;
                            padding-top: 6px;
                        }
                    }

                    .data {
                        width: 100%;
                        max-width: calc(100% - 202px);
                        p {
                            @include font(16px, 24px, $BE-blue, 16px, $font-body);
                        }
                    }

                    > .quote-info {
                        width: calc(50% - 20px);
                        &.large {
                            width: 100%;
                            flex-flow: column;
                            align-items: flex-start;
                            .injected-info {
                                span:nth-child(even) {
                                    text-align: left;
                                }

                                & + .injected-info {
                                    margin: 4px 0 0 0;
                                }
                            }
                        }
                    }
                }

                .quote-info {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 0 36px;
                    h5 {
                        width: 100%;
                        @include font(12px, 15px, #4C9BC6, 16px, $font-medium);
                        text-transform: uppercase;
                    }

                    ul {
                        margin: 0;
                        padding: 0 0 0 33px;
                        max-width: 606px;
                        li {
                            @include font(16px, 24px, $BE-blue, 5px, $font-body);
                        }
                    }

                    p {
                        @include font(16px, 24px, $BE-blue, 5px, $font-body);
                    }

                    .injected-info {
                        display: flex;
                        span {
                            display: block;
                            background-color: $white;
                            padding: 8px 16px;
                            @include font(16px, 28px, $BE-blue, 0, $font-body);
                            min-width: 98px;
                            text-align: center;
                            & + span {
                                margin-left: 4px;
                            }

                            tag {
                                text-transform: capitalize;
                            }
                        }

                        & + .injected-info {
                            margin-left: 36px;
                        }

                        &.table {
                            display: grid;
                            grid-gap: 4px;
                            grid-template-columns: auto auto;
                            span {
                                margin: 0;
                            }
                        }
                    }
                }


                &.service {
                    .service-table {
                        display: table;
                        width: 100%;
                        margin: 0 0 36px;
                        max-width: 484px;
                        > div {
                            display: table-row;
                            > div {
                                display: table-cell;
                                font-size: 16px;
                                line-height: 45px;
                                &.label {
                                    width: 262px;
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    font-family: $font-medium;
                                    color: #4C9BC6;
                                }
                            }

                            &.head > div {
                                padding: 0 0 18px;
                                font-size: 20px;
                                line-height: 24px;
                                font-family: $font-medium;
                                color: $BE-blue;
                            }

                            &.item > div {
                                border-top: 1px solid $BE-blue;
                            }

                            &:last-child > div {
                                font-family: $font-bold;
                            }
                        }
                    }

                    > p {
                        @include font(16px, 24px, $BE-blue, 38px, $font-body);
                    }
                }
            }

            aside {
                width: 100%;
                max-width: 420px;
                margin-top: -25px;
                .title {
                    @include translucent;
                    padding: 40px 30px 50px;
                    .head {
                        padding: 0 15px;
                        h2 {
                            @include font(26px, 34px, $BE-blue, 24px, $font-bold);
                        }

                        h3 {
                            @include font(23px, 24px, $navy, 12px, $font-bold);
                        }

                        p {
                            @include font(16px, 24px, $BE-blue, 0, $font-body);
                        }

                        button {
                            margin: 20px 0 0 0;
                        }
                    }

                    .contact-area {
                        margin-top: 40px;
                        padding: 30px 15px 0;
                        border-top: 1px solid $navy;
                        h3 {
                            @include font(17px, 24px, $navy, 5px, $font-bold);
                        }

                        p {
                            @include font(17px, 24px, $navy, 0, $font-body);
                            a {
                                display: inline-block;
                                font-size: 17px;
                                line-height: 24px;
                                margin-left: 6px;
                            }
                        }
                    }
                }

                .contact-form {
                    @include translucent;
                    padding: 42px 30px 30px;
                    form .form-item {
                        display: flex;
                        flex-flow: column-reverse;
                        input:focus + label {
                            color: $green;
                        }
                    }
                }
            }

            &[result="service"] {
                aside {
                    display: none;
                    &#service-agreement {
                        display: block;
                    }
                }

                .wrapper.service {
                    display: block;
                }

                & + .main-content + .complete-form .form-wrapper.service-form {
                    display: block;
                }
            }

            &[result="full"] {
                aside {
                    display: none;
                    &#full-service {
                        display: block;
                    }
                }
                .wrapper.full {
                    display: block;
                }

                & + .main-content + .complete-form .form-wrapper.full-form {
                    display: block;
                }
            }
        }

        &.form-submitted {
            > .step-title {
                display: none;
            }

            > .contact-form {
                margin: 0 auto;
                > .title {
                    display: none;
                }
            }

            > .complete-form {
                max-width: 100%;
                .form-wrapper.full-form {
                    margin: 0 auto;
                    .gform_confirmation_wrapper {
                        padding: 70px 60px;
                        .gform_confirmation_message {
                            color: $blue;
                            font-size: 20px;
                            line-height: 30px;
                            font-family: $font-medium;
                        }
                    }
                }
            }
        }
    }
    
    &[step="1"] {
        aside {
            .page-title .back {
                span {
                    &.previous {
                        display: none;
                    }
                    &.trends {
                        display: block;
                    }
                }
            }
            .progress-bar .steps .step:first-child {
                background-color: $BE-blue;
            }
        }

        .main-content[step="1"] {
            display: flex;
            .wrapper {
                max-width: 544px;
            }
        }
    }
    
    &[step="2"] {
        aside .progress-bar .steps .step:nth-child(-n + 2) {
            background-color: $BE-blue;
        }

        .main-content[step="2"] {
            display: flex;
        }
    }

    &[step="3"] {
        aside .progress-bar .steps .step:nth-child(-n + 3) {
            background-color: $BE-blue;
        }

        .main-content[step="3"] {
            display: flex;
        }
    }

    &[step="4"] {
        aside .progress-bar .steps .step:nth-child(-n + 4) {
            background-color: $BE-blue;
        }

        .main-content[step="4"] {
            display: flex;
        }
    }

    // &[step="5"] {
    //     aside .progress-bar .steps .step:nth-child(-n + 5) {
    //         background-color: $BE-blue;
    //     }

    //     .main-content[step="5"] {
    //         display: flex;
    //     }
    // }

    &[step="5"] {
        flex-flow: column;
        justify-content: flex-start;
        padding: 37px;

        aside {
            top: 0;
            position: relative;
            width: 100%;
            left: 0;
            transform: none;
            .progress-bar,
            .page-title {
                display: none;
            }
        }

        .main-content[step="5"] {
            display: flex;
        }
    }

    &[step="6"] {
        flex-flow: column;
        justify-content: flex-start;
        padding: 37px;
        aside {
            top: 0;
            position: relative;
            width: 100%;
            left: 0;
            transform: none;
            .progress-bar,
            .page-title {
                display: none;
            }
        }

        .main-content[step="6"] {
            display: flex;
            flex-flow: row;
            align-items: center;
        }

        section.no-branch {
            width: 100%;
            min-height: calc(100vh - 133px);
            display: flex;
            align-items: center;
            max-width: 965px;
            margin: 0 auto;
            .simple-wrapper {
                width: 100%;
                display: flex;
                justify-content: space-between;
                .description {
                    width: 311px;
                    @include translucent;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    padding: 26px;
                    h2 {
                        @include font(26px, 34px, $BE-blue, 16px, $font-bold);
                    }
    
                    p {
                        @include font(16px, 24px, $BE-blue, 0, $font-body);
                    }
                }
    
                // form
                #no-branch-form {
                    width: 100%;
                    max-width: calc(100% - 327px);
                    .gform_wrapper {
                        margin: 0;
                        form {
                            width: 100%;
                            display: flex;
                            flex-flow: column;
                            @include translucent;
                            padding: 26px;
                            margin: 0;
                        }
                    }

                    .gform_confirmation_wrapper,
                    .gform_not_found {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-flow: column;
                        align-items: center;
                        justify-content: center;
                        @include translucent;
                        padding: 70px 60px;
                        margin: 0;
                        .gform_confirmation_message {
                            color: $blue;
                            font-size: 29px;
                            line-height: 48px;
                            font-family: $font-medium;
                            text-align: center;
                        }
                    }

                    .form-item
                    li.gfield {
                        display: flex;
                        flex-flow: column;
                        label {
                            order: -1;
                        }
    
                        input:focus + label {
                            color: $green;
                        }
                    }
                }
            }

            @media screen and (max-width: 768px) {
                flex-flow: column;
                .description {
                    width: 100%;
                    margin: 0 0 16px;
                }
    
                #no-branch-form {
                    max-width: 100%!important;
                }
            }
        }
    }

    &[step="7"] {
        flex-flow: column;
        justify-content: flex-start;
        padding: 37px;
        aside {
            top: 0;
            position: relative;
            width: 100%;
            left: 0;
            transform: none;
            .progress-bar,
            .page-title {
                display: none;
            }
        }

        .main-content[step="7"] {
            display: flex;
            .back {
                position: absolute;
                left: 40px;
                top: 120px;
            }
        }

        section.complete-form {
            width: 100%;
            display: flex;
            align-items: center;
            max-width: 965px;
            margin-top: 40px;
            .simple-wrapper {
                width: 100%;
                .description {
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    padding: 32px 0;
                    h2{
                        @include font(28px, 34px, $BE-blue, 16px, $font-bold);
                    }
    
                    p {
                        @include font(16px, 24px, $BE-blue, 0, $font-body);
                    }
                }
    
                // form
                .form-wrapper {
                    display: none;
                    width: 100%;
                    max-width: 740px;
                    .gform_wrapper {
                        margin: 0;
                        form {
                            display: flex;
                            flex-flow: column;
                            @include translucent;
                            padding: 26px;
                            margin: 0;
                        }
                    }

                    .gform_confirmation_wrapper,
                    .gform_not_found {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-flow: column;
                        align-items: center;
                        justify-content: center;
                        @include translucent;
                        padding: 26px;
                        margin: 0;
                    }

                    .form-item
                    li.gfield {
                        display: flex;
                        flex-flow: column;
                        label {
                            order: -1;
                        }
    
                        input:focus + label {
                            color: $green;
                        }
                    }
                }
            }

            @media screen and (max-width: 768px) {
                flex-flow: column;
                .description {
                    width: 100%;
                    margin: 0 0 16px;
                }
    
                #no-branch-form {
                    max-width: 100%!important;
                }
            }
        }
    }

    &[flow="community"] {
        > .community-flow {
            display: block;
        }
    }

    &[flow="air"] {
        > .air-flow {
            display: block;
        }
    }

    &[flow="commercial"] {
        > .commercial-flow {
            display: block;
        }
    }

    &[flow="in-home"] {
        > .in-home-flow {
            display: block;
        }
    }
    
    &[flow="air"],
    &[flow="in-home"] {
        > aside .progress-bar .step:nth-child(n + 3) {
            display: none;
        }

        .contact-form {
            max-width: 540px;
            form .form-item {
                display: flex;
                flex-flow: column-reverse;
            }
        }
    }

    &[flow="commercial"] {
        > aside .progress-bar .step:nth-child(n + 3) {
            display: none;
        }

        .contact-form {
            width: 100%;
            min-height: calc(100vh - 133px);
            display: flex;
            align-items: center;
            max-width: 965px;
            margin: 0 auto;
            .comm_wrapper {
                width: 100%;
                display: flex;
                justify-content: space-between;
                .description {
                    width: 311px;
                    box-shadow: -1px 16px 26px 0 rgba(76, 155, 198, 0.22);
                    backdrop-filter: blur(7px);
                    background-color: rgba(255, 255, 255, 0.27);
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    padding: 26px;
                    h3 {
                        font-size: 26px;
                        line-height: 31px;
                        margin: 0 0 24px;
                        color: $BE-blue;
                    }

                    p {
                        font-size: 16px;
                        line-height: 24px;
                        color: $BE-blue;
                    }
                }

                #commercial-form {
                    width: 100%;
                    max-width: calc(100% - 327px);
                    .gform_confirmation_wrapper,
                    .gform_not_found {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-flow: column;
                        align-items: center;
                        justify-content: center;
                        @include translucent;
                        padding: 26px;
                        margin: 0;
                    }

                    .gform_wrapper {
                        margin: 0;
                        form {
                            width: 100%;
                            display: flex;
                            flex-flow: column;
                            @include translucent;
                            padding: 26px;
                            margin: 0;
                            .form-actions input {
                                @media screen and (min-width: 380px) {
                                    min-width: 273px;
                                }
                            }
                        }
                    }
                }
            }

            @media screen and (max-width: 990px) {
                align-items: flex-start;
                min-height: unset;
            }

            @media screen and (max-width: 768px) {
                .comm_wrapper {
                    flex-flow: column;
                    .description {
                        width: 100%;
                        margin: 0 0 16px;
                    }
        
                    #commercial-form {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        &[step="6"] .main-content[step="6"] {
            flex-flow: column;
            align-items: flex-start;
            margin-top: 36px;
            section.no-branch {
                min-height: unset;
                margin-top: 36px;
            }
        }
    }

    @media screen and (max-width: 880px), screen and (min-width: 991px) and (max-width: 1170px) {
        .main-content .question-wrapper.three-column .answers {
            grid-template-columns: repeat(2, auto);
        }
    }

    @media screen and (max-width: 800px), screen and (min-width: 991px) and (max-width: 1100px) {
        .main-content .question-wrapper.number-type .state-popup {
            right: 0;
            left: auto;
            transform: translate(0, 26px);
            top: 100%;
        }

        .main-content .dependency-container .question-wrapper.machine-sizer .inputs .question-wrapper {
            flex-flow: column;
            h3 {
                max-width: 100%;
                margin: 0 0 16px;
            }
        }
    }

    @media screen and (max-width: 1100px) {
        .main-content[step="5"] {
            .wrapper {
                max-width: calc(100% - 380px);
                section {
                    .title {
                        width: 160px;
                    }

                    .data {
                        max-width: calc(100% - 160px);
                    }
                }
            }

            aside {
                max-width: 360px;
                margin-top: 25px;
            }
        }
    }

    @media screen and (max-width: 990px) {
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: column;
        padding-left: 37px;
        padding-top: 28px;
        aside {
            width: 100%;
            position: relative;
            padding: 0;
            top: 0;
            margin: 0 0 36px;

        }

        .main-content[step="5"] {
            flex-flow: column;
            .wrapper {
                max-width: 100%;
                padding: 20px;
                section > .quote-info {
                    width: 100%;
                }
            }

            aside {
                max-width: 100%;
                .title {
                    padding: 20px;
                    .head {
                        padding: 0;
                    }

                    .contact-area {
                        padding: 20px 0 0 0;
                        margin-top: 30px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 769px) and (max-width: 900px), (max-width: 500px) {
        .main-content[step="5"] .wrapper section {
            flex-flow: column;
            .title {
                width: 100%;
                margin: 0 0 16px;
                padding: 0;
            }

            .data {
                max-width: 100%;
            }
        }
    }

    @media screen and (max-width: 768px) {
        &[step="6"] section.no-branch .simple-wrapper {
            flex-flow: column;
            .description {
                width: 100%;
            }

            form {
                max-width: 100%;
            }
        }
    }

    @media screen and (max-width: 630px) {
        .main-content .dependency-container .question-wrapper:not(:first-child):not(.visible) {
            margin-top: -160px;
        }

        .main-content .question-wrapper {
            width: 100%;
            .answers {
                grid-template-columns: repeat(1, auto);
                .answer-button {
                    width: 100%;
                    &.large {
                        max-width: 100%;
                    }
                }   
            }

            &.three-column .answers {
                grid-template-columns: repeat(1, auto);
            }
        }

        .main-content .dependency-container .question-wrapper.machine-sizer:not(:first-child):not(.visible) {
            display: block;
            margin-top: -612px;
            h6 {
                margin: 0 0 16px;
            }

            .inputs {
                margin: 0 0 36px;
            }

            .estimation {
                max-width: 290px;
                margin: 0 auto;
            }
        }
    }

    @media screen and (max-width: 580px) {
        .main-content .question-wrapper.state-input {
            flex-wrap: wrap;
            h3 {
                max-width: 100%;
                margin: 0 0 16px;
            }

            .state-popup {
                position: relative;
                transform: none;
                right: auto;
                width: 100%;
            }
        }

        .main-content[step="5"] .wrapper .quote-info .injected-info:not(.table) {
            width: 100%;
            span:last-child {
                width: 100%;
            }

            + .injected-info {
                margin-left: 0;
                margin-top: 4px;
            }
        }

        .main-content[step="5"] .wrapper.service .service-table > div {
            display: block;
            width: 100%;
            > div {
                display: block;
                width: 100%;
                &.label {
                    width: 100%;
                    display: block;
                }

                &:last-child {
                    border-top: none;
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        padding: 28px 20px 60px!important;
        .main-content .question-wrapper.number-type {
            flex-flow: column;
            h3 {
                max-width: 100%;
                margin: 0 0 16px;
            }
        }

        &[step="5"] {
            padding: 28px 20px 60px;
        }

        .logo a img {
            max-width: 140px;
        }
    }

    // gravity forms overwrite
    .gform_wrapper.gravity-theme {
        .gfield {
            grid-column: 1/13;
            &.gfield--width-full {
                grid-column: span 12;
            }
        }
    }
}