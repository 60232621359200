// Hide Salesforce PDF
.gfield.sf-hidden {
    display: none;
}

#contact-csc {
    padding: 37px;
    min-height: 100vh;
    header {
        display: flex;
        align-items: center;
        .page-title {
            margin: 0 0 0 15px;
            h1 {
                margin: 0;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                font-family: $font-bold;
                color: $BE-blue;
            }
        }
    }

    section.contact {
        min-height: calc(100vh - 133px);
        display: flex;
        align-items: center;
        max-width: 965px;
        margin: 0 auto;
        .wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .description {
                width: 311px;
                @include translucent;
                display: flex;
                flex-flow: column;
                justify-content: center;
                padding: 26px;
                h1 {
                    font-size: 26px;
                    line-height: 34px;
                    margin: 0 0 16px;
                    color: $BE-blue;
                    font-family: $font-bold;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    margin: 0;
                    color: $BE-blue;
                }
            }

            .gform_wrapper {
                width: 100%;
                max-width: calc(100% - 327px);
                form {
                    width: 100%;
                    .gfield {
                        margin: 0 0 10px;
                        @media screen and (min-width: 641px) {
                            &.half {
                                grid-column: 1 / 7;
                            }
                            &.quorter {
                                grid-column: 7 / 10;
                                & + .quorter {
                                    grid-column: 10 / 14;
                                }
                            }
                        }
                    }
    
                    h3.gsection_title {
                        text-transform: uppercase;
                        font-size: 18px;
                        line-height: 20px;
                        margin: 10px 0 0 0;
                    }
                    
                    // altered style for select
                    .ginput_container_select {
                        position: relative;
                    }
                    .select2 {
                        min-height: unset;
                        background: $white;
                        border-radius: 4px;
                        .select2-selection--single {
                            .select2-selection__rendered {
                                font-size: 16px;
                                line-height: 40px;
                                min-height: 40px;
                                padding: 0 16px;
                            }
    
                            .select2-selection__arrow {
                                background-color: $white;
                                width: 34px;
                                b {
                                    background-image: none;
                                    width: 0;
                                    height: 0;
                                    border-top: 8px solid $navy;
                                    border-right: 6px solid $white;
                                    border-left: 6px solid $white;
                                }
                            }
                        }
                    }
                }
            }

            form,
            .gform_confirmation_message {
                margin: 0;
                width: 100%;
                @include translucent;
                padding: 26px;
                display: flex;
                flex-flow: column;
                .form-item {
                    display: flex;
                    flex-flow: column;
                    label {
                        order: -1;
                    }

                    input:focus + label {
                        color: $green;
                    }
                }
            }
            
            .gform_confirmation_message {
                height: 100%;
                justify-content: center;
            }
        }
    }

    @media screen and (max-width: 768px) {
        section.contact .wrapper {
            flex-flow: column;
            .description {
                width: 100%;
                margin: 0 0 16px;
            }

            .gform_wrapper {
                max-width: 100%;
            }
        }
    }

    @media screen and (max-width: 480px) {
        padding: 20px 4px;
        header {
            margin: 0 0 30px;
            padding: 0 10px 0 16px;
        }
    }
}