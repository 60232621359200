h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-title;
	line-height: 2.4rem;
	margin: 0 0 20px 0;
}

h1 {
	font-size: 50px;
	line-height: 60px;
	color: $blue;
	font-family: $font-medium;
	font-weight: 600;
}

h2 {
	font-size: 40px;
	line-height: 48px;
	font-family: $font-medium;
	font-weight: 600;
}

h3 {
	font-size: 32px;
	line-height: 38px;
	font-family: $font-bold;
	font-weight: 700;
	color: $blue;
	margin: 0 0 24px 0;
	@media screen and (max-width: 1440px) {
		font-size: 24px;
		line-height: 28px;
	}
}

h4 {
	font-size: 28px;
	line-height: 34px;
	font-family: $font-medium;
	font-weight: 600;
	color: $blue;
	margin: 0 0 20px 0;
}

h5 {
	font-size: 24px;
	line-height: 32px;
	font-family: $font-bold;
	font-weight: 700;
	color: #3B474E;
	margin: 0 0 20px 0;
}

h6 {
	font-size: 20px;
	line-height: 28px;
	margin: 0 0 20px 0;
}

.block-text {
	color: $footer-grey;
	background-color: $white;
	font-family: $font-body;

	.thank-you-page {
		text-align: center;
		> h1 {
			display: block;
			width: calc(100% - 30px);
			padding: 119px 0;
			margin: 0 auto;
		}

		> .box {
			width: calc(100% - 30px);
			max-width: 749px;
			border: 4px solid $pool-blue;
			margin: 0 auto 143px;
			padding: 32px 24px;
			> p {
				font-size: 24px;
				line-height: 32px;
				color: $deep-navy;
				margin: 0 0 24px;
			}

			> a {
				position: relative;
				display: table;
				margin: 0 auto;
				background: linear-gradient(to bottom, $green 50%, $hover-blue 50%);
				background-size: 100% 200%;
				background-position: center top;
				background-repeat: no-repeat;
				border-radius: 20px;
				color: $white;
				padding: 0 42px;
				font-size: 12px;
				line-height: 26px;
				font-family: $font-medium;
				font-weight: 500;
				max-width: 100%;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				box-sizing: border-box;
				transition: background 0.4s ease;
				border: none;
				cursor: pointer;
				&:hover {
					text-decoration: none;
					color: $white;
					background-position: center bottom;
				}
			}
		}

		@media screen and (max-width: 768px) {
			> h1 {
				padding: 80px 0;
			}

			> .box {
				margin: 0 auto 60px;
				padding: 30px 15px;
				> p {
					font-size: 18px;
					line-height: 26px;
				}
			}
		}
	}

	blockquote {
		margin-left: 0;
		margin-right: 0;
		margin-top: 0;
		clear: left;
		width: 100%;
		a.cta:hover {
			color: $blue;
			text-decoration: none;
		}
	}

	table {
		border: 1px solid $light-blue;
		border-collapse: collapse;
		border-spacing: 0;
		text-align: left;
		width: 100%;
        thead {
            background-color: $light-blue;
            tr {
                color: $white;
            }
        }

        tbody {
            tr:nth-child(even) {
                background-color: $mobile-blue;
            }
        }
	}

	th,
	td {
		padding: 0.5em 1em;
	}

	@media screen and (max-width: 600px) {
		& + .request-services-bar {
			margin: 0;
		}
	}

	form.post-password-form {
		display: flex;
		max-width: 400px;
		text-align: center;
		flex-flow: column;
		align-items: center;
		p:first-child {
			font-size: 16px;
			line-height: 21px;
			color: $blue;
		}

		label {
			display: inline-block;
			vertical-align: bottom;
			font-size: 14px;
			line-height: 12px;
			margin: 0;
			padding: 8px 0;
			font-family: $font-medium;
			color: $blue;
			border-bottom: 1px solid $blue;
			input {
				display: inline-block;
				vertical-align: bottom;
				border: none;
				box-shadow: none;
				width: 200px;
				margin: 0;
				padding: 0 25px;
				font-size: 16px;
				min-height: unset;
				color: $footer-grey;
				font-family: $font-body;
				border-radius: 0;
				&:focus {
					outline: none;
					box-shadow: none;
				}
			}
		}

		input[type="submit"] {
			@extend button;
			margin: 40px 0 0 0;
			min-width: unset!important;
		}
	}
}

article {
	a {
		color: $hover-blue;
		text-decoration: none;
		&:hover {
			color: $light-blue;
			text-decoration: underline;
		}
	}

	figure {
		float: left;
		width: 270px;
		margin: 20px 40px 20px 0;
		figcaption {
			font-size: 14px;
			line-height: 20px;
		}

		&.alignright {
			float: right;
			margin: 20px 10px 20px 40px;
		}

		&.alignmiddle,
		&.aligncenter {
			float: none;
			margin: 20px auto;
		}

		@media screen and (max-width: 600px) {
			float: none!important;
			width: 100%!important;
			margin: 20px 0!important;
			max-width: 100%;
			img {
				margin: 0 auto 20px;
				display: block;
				width: 100%;
			}
		}
	}

	ol,
	ul {
		margin: 20px 0;
		padding: 0 0 0 20px;
		li {
			font-family: $font-body;
			font-size: 20px;
			line-height: 32px;
			font-weight: 400;
			margin: 0 0 5px;
			color: #707070;
		}
	}
}

.block-text-title {
	text-align: center;
}

.block-text-layout {
	width: 100%;
	& + *:not(.request-services-bar):not(.three-up):not(.image-text-cta):not(.video-component) {
		margin-top: 34px;
	}

	> h2 {
		color: $pool-blue;
	}

	> .line-through {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;
		margin: 0 auto 40px;
        span {
            position: relative;
            padding: 0 20px;
            display: block;
            margin: 0 auto;
            background-color: $white;
        }
        &:before {
            content: "";
            width: 100%;
            height: 1px;
            background-color: #333;
            position: absolute;
            left: 0;
            top: 50%;
        }

		&:first-child {
			margin-top: 60px;
		}
	}
}