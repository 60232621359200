.news-events-past-events,
.news-events-index-with-filters {
	background: $mobile-blue;
	margin: 0;
}

.news-events-index-with-filters-header {
	// display: flex;
	// flex-direction: column;
	// justify-content: space-between;
	padding: 30px 0;

	.taxonomy-block-title {
		color: $blue;
		font-family: $font-medium;
		font-size: 18px;
		font-weight: normal;
		line-height: 21px;
		margin: 0;
	}

	.taxonomy-filter {
		margin-top: 5px;
		padding: 5px;
	}

	.clear {
		color: $blue;
		font-family: $font-medium;
		font-size: 14px;
		line-height: 16px;
		margin-top: 13px;
	}

	@media screen and (max-width: 1280px) {
		> .container {
			max-width: 100%;
			.news-events-index-with-filters-title {
				padding-right: 20px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
	
	@media screen and (max-width: 768px) {
		> .container {
			.news-events-index-with-filters-title {
				max-width: 100%;
				white-space: normal;
				padding: 0;
				text-align: center;
			}

			.active-topic-items {
				display: none;
			}
		}
	}
}

.news-events-index-with-filters-headerlayout {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
	@media screen and (max-width: 1024px) {
		flex-direction: column!important;
		justify-content: center;
		white-space: normal;
		h3 {
			text-align: center;
			margin: 0 0 30px 0;
			white-space: normal;
			max-width: 100%;
		}

		.active-topic-items {
			display: none;
		}
	}
}

.news-events-index-with-filters-title {
	color: $blue;
	font-family: $font-medium;
	font-size: 38px;
	font-weight: normal;
	line-height: 46px;
	margin: 24px 0;
	white-space: nowrap;
}

.active-topic-items {
	position: absolute;
	bottom: -10px;
	left: 0;
	max-width: 100%;
	font-size: 14px;
    line-height: 14px;
	color: $cool-grey;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.news-events-index-with-filters-layout {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 auto;
	overflow: auto;
	padding: 0 15px 100px 15px;
}

.news-events-index-with-filters-cell1,
.news-events-index-with-filters-cell2,
.news-events-index-with-filters-cell3 {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.news-events-index-with-filters-cardcell {
	display: flex;
	flex-direction: row;
	padding: 10px;
}

.news-events-index-with-filters-cardlayout {
	background-color: $white;
	box-shadow: 0 3px 6px #00000020;
	box-sizing: border-box;
	display: block;
	padding: 12px;
	text-decoration: none;
	transition: box-shadow 0.25s, transform 0.25s;
	width: 100%;

	&:focus,
	&:hover {
		box-shadow: 0 8px 12px #00000010;
		text-decoration: none;
		transform: translateY(-5px);
	}
}

.news-events-index-with-filters-cardinner {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.news-events-index-with-filters-cardimage {
	background-position: center center;
	background-size: cover;
	padding-bottom: 50%; // This is a 2:1 aspect ratio div.
	position: relative;
	width: 100%;
}

.news-events-index-with-filters-cardlabel {
	background-color: $white;
	color: $footer-grey;
	font-family: $font-bold;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	margin: 0;
	padding: 3px 6px;

	position: absolute;
	bottom: 0;
	left: 6px;
}

.news-events-index-with-filters-cardtitle {
	color: $blue;
	font-family: $font-medium;
	font-size: 20px;
	font-weight: normal;
	line-height: 24px;
	margin: 0;
	padding: 12px;
}

.news-events-index-with-filters-cardtags {
	color: $cool-grey;
	font-family: -$font-body;
	font-size: 14px;
	line-height: 14px;
	margin: 0;
	padding: 0 12px 12px 12px;
}

.news-events-index-with-filters-excerpt {
	color: $text-grey;
	font-family: -$font-body;
	padding: 0 12px 12px 12px;
}

.news-events-index-with-filters-dateline {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: auto;
	padding: 12px 12px 0 12px;
	width: 100%;
}

.news-events-index-with-filters-readmore {
	color: $light-blue;
	font-family: -$font-body;
	font-size: 14px;
	line-height: 14px;
}

.news-events-index-with-filters-published {
	color: $soft-navy;
	font-size: 14px;
	font-weight: normal;
	line-height: 28px;
	margin: 0;
	padding: 15px 12px 0;
}

.news-events-index-with-filters-location {
	font-size: 16px;
	line-height: 23px;
	font-family: $font-bold;
	color: $footer-grey;
	max-width: calc(100% - 120px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.news-events-index-with-filters-header {
	form {
		margin: 0;
		display: flex;
		align-items: center;
		.taxonomy-filter {
			position: relative;
			h4 {
				font-size: 18px;
				line-height: 21px;
				color: $blue;
				margin: 0;
				cursor: pointer;
				font-family: -$font-body;
				.counter {
					display: inline-block;
					vertical-align: middle;
					width: 20px;
					height: 20px;
					background-color: #142A65;
					border-radius: 100%;
					color: #FFF;
					text-align: center;
					font-size: 14px;
					line-height: 22px;
					text-indent: 1px;
					margin: 0 0 0 6px;
					&:empty {
						display: none;
					}
				}
				&:after {
					content: "";
					display: inline-block;
					vertical-align: middle;
					border-top: 8px solid $green;
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					margin: 0 0 0 13px;
				}
			}

			.items-container {
				display: none;
				position: absolute;
				top: calc(100% + 15px);
				right: -15px;
				background: $white;
				padding: 25px 35px;
				z-index: 2;
				white-space: nowrap;
				.form-item {
					margin: 0 0 10px 0;
					input {
						display: none;
						&:checked + label:before {
							background: $blue;
							border: 2px solid $white;
						}
					}

					label {
						color: $blue;
						cursor: pointer;
						font-size: 14px;
						line-height: 26px;
						font-family: $font-medium;
						&:before {
							content: "";
							width: 14px;
							height: 14px;
							display: inline-block;
							vertical-align: middle;
							background: $white;
							outline: 1px solid $blue;
							margin: 0 15px 0 0;
							box-sizing: border-box;
						}
					}

					&:last-child {
						margin: 0;
					}
				}

				.mobile {
					display: none;
				}
			}

			@media screen and (min-width: $desktop) {
				& + .taxonomy-filter {
					margin-left: 30px;
				}
			}

			&.open {
				h4:after {
					transform: rotate(180deg);
				}

				> .items-container {
					display: block;
				}
			}
		}

		> .clear {
			color: $blue;
			font-family: $font-medium;
			font-size: 14px;
			line-height: 16px;
			text-decoration: underline;
			&:hover {
				color: $hover-blue;
			}

			@media screen and (min-width: $desktop) {
				margin: 0 0 0 44px;
			}
		}

		.form-actions {
			display: none;
		}
	}

	@media screen and (max-width: 480px) {
		.taxonomy-filter h4 .counter {
			font-size: 12px;
			line-height: 20px;
			text-indent: 0;
		}
	}
}

.news-events-index-with-filter .pagination {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 20px 0;
    background-color: $mobile-blue;
	> .container {
		display: flex;
		justify-content: center;
		text-align: center;
		span.current,
		a {
			font-size: 24px;
			line-height: 33px;
			color: $footer-grey;
			font-family: $font-medium;
			margin: 0 15px;
			&.prev {
				&:before {
					content: "";
					display: inline-block;
					vertical-align: middle;
					width: 30px;
					height: 14px;
					background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTFGRUYwNTMzMDdGMTFFQThFM0FFMDM1NEQyNTExREIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTFGRUYwNTQzMDdGMTFFQThFM0FFMDM1NEQyNTExREIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1MUZFRjA1MTMwN0YxMUVBOEUzQUUwMzU0RDI1MTFEQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1MUZFRjA1MjMwN0YxMUVBOEUzQUUwMzU0RDI1MTFEQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pl2LuqYAAAHvSURBVHjaYvj//z8DOfjKo5eC5OrFhpkYyADFXdNmlTZ2Hjx/76kkA5UAEzmOOHb6fOD7j590p81fNpVaDmEhRXFydefGa7fuWAGZIjKSEkdLs1NiqeUQRlD8kOgIBi1V5WNVBZmhymL8v+gaIuiOmNtW4c9AZcA0GByBN0RuvXjPXds5YdujJ8+0aO0InCECckR1W99OoCPsQHwrE8P1tHQEVofAHPHk+QtrIPcNyBG95VlpDDQGTIPBEShpBFRKNnRP3vLqzVsjWHTQyxHwcgTkCFBIgEpLkKCmmgpDZKC3GTMT81+6OWTfuWsG3VPnLII5YoDAG6aPn76Iff/xk49hgAE4ao5cvq3VOnHG6g8fP4HLDBlJcYZgH/cgujsEllir2vr2wBzj5+5UX5kS2UQvh8Czr6GS9PO2qiIXAX6+ayD+pp37ctvnLK+je4ggZ2OkkHnj4WQ3pz4ztpLuDoE5prlv6rrnL19bgAs2U2CZUkbbMgVnewSjlKWxY3A2A9QkBL+2VhW5g1pioBYZqHkIaibSPURwhYy5kf7WCZU5CXR3CNa2iZrKsbmt5f50dwi8tVbVsfHa7btWtHAMSd0JUOMI1FIDsUHNx+ymCUsHrF8DcwwHO/vXMH/PDqrFDbldxKNX76pTs8sJEGAAkZ3kUaMGzFIAAAAASUVORK5CYII=');
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
					margin: -3px 13px 0 15px;
				}
			}
			&.next {
				margin-left: 45px;
				&:after {
					content: "";
					display: inline-block;
					vertical-align: middle;
					width: 30px;
					height: 14px;
					background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTFGRUYwNTMzMDdGMTFFQThFM0FFMDM1NEQyNTExREIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTFGRUYwNTQzMDdGMTFFQThFM0FFMDM1NEQyNTExREIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1MUZFRjA1MTMwN0YxMUVBOEUzQUUwMzU0RDI1MTFEQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1MUZFRjA1MjMwN0YxMUVBOEUzQUUwMzU0RDI1MTFEQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pl2LuqYAAAHvSURBVHjaYvj//z8DOfjKo5eC5OrFhpkYyADFXdNmlTZ2Hjx/76kkA5UAEzmOOHb6fOD7j590p81fNpVaDmEhRXFydefGa7fuWAGZIjKSEkdLs1NiqeUQRlD8kOgIBi1V5WNVBZmhymL8v+gaIuiOmNtW4c9AZcA0GByBN0RuvXjPXds5YdujJ8+0aO0InCECckR1W99OoCPsQHwrE8P1tHQEVofAHPHk+QtrIPcNyBG95VlpDDQGTIPBEShpBFRKNnRP3vLqzVsjWHTQyxHwcgTkCFBIgEpLkKCmmgpDZKC3GTMT81+6OWTfuWsG3VPnLII5YoDAG6aPn76Iff/xk49hgAE4ao5cvq3VOnHG6g8fP4HLDBlJcYZgH/cgujsEllir2vr2wBzj5+5UX5kS2UQvh8Czr6GS9PO2qiIXAX6+ayD+pp37ctvnLK+je4ggZ2OkkHnj4WQ3pz4ztpLuDoE5prlv6rrnL19bgAs2U2CZUkbbMgVnewSjlKWxY3A2A9QkBL+2VhW5g1pioBYZqHkIaibSPURwhYy5kf7WCZU5CXR3CNa2iZrKsbmt5f50dwi8tVbVsfHa7btWtHAMSd0JUOMI1FIDsUHNx+ymCUsHrF8DcwwHO/vXMH/PDqrFDbldxKNX76pTs8sJEGAAkZ3kUaMGzFIAAAAASUVORK5CYII=');
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
					margin: -3px 15px 0 20px;
					transform: rotate(180deg);
				}
			}

			&:hover {
				text-decoration: none;
			}
		}

		span.current {
			text-decoration: underline;
			&:hover {
				cursor: default;
				text-decoration: underline;
			}
		}
	}

	@media screen and (max-width: 660px) {
        > .container  {
            span.current.prev, 
            a.prev {
                margin: 0 15px 0 0;
            }

            span.current.next, 
            a.next {
                margin: 0 0 0 15px;
            }

            span.current, 
            a {
                font-size: 18px;
                line-height: 28px;
                margin: 0 10px;
            }
        }
	}
	
	@media screen and (max-width: 480px) {
        > .container  {
            span.current.prev, 
            a.prev,
            span.current.next, 
            a.next {
                font-size: 0;
                color: transparent;
                &:after,
                &:before {
                    margin: 0;
                }
            }
        }
    }

    @media screen and (max-width: 380px) {
        > .container  {
            span.current.prev, 
            a.prev,
            span.current.next, 
            a.next {
                margin: 0;
            }
            
            span.current, 
            a {
                font-size: 20px;
                margin: 0 15px;
            }
        }
    }
}

@media (min-width: $desktop) {
	.news-events-index-with-filters-headerlayout {
		align-items: center;
		flex-direction: row;

		form {
			align-items: center;
			display: flex;
			flex-direction: row;
			margin: 0;
			min-width: 402px;
			justify-content: flex-end;
		}
	}

	.news-events-index-with-filters-layout {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.news-events-index-with-filters-cell1 {
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;

		.news-events-index-with-filters-cardlabel {
			font-size: 20px;
			line-height: 24px;
			padding: 4px 8px;
			left: 8px;
		}

		.news-events-index-with-filters-cardtitle {
			font-size: 30px;
			line-height: 36px;
		}

		.news-events-index-with-filters-readmore {
			font-size: 20px;
			line-height: 28px;
		}

		.news-events-index-with-filters-published {
			font-size: 24px;
			line-height: 28px;
			padding: 38px 12px 0;
		}
		.news-events-index-with-filters-location {
			font-size: 24px;
			line-height: 28px;
		}

		.news-events-index-with-filters-cardcell {
			width: 100%;
		}
	}

	.news-events-index-with-filters-cell2 {
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;

		.news-events-index-with-filters-excerpt {
			display: none;
		}
	}

	.news-events-index-with-filters-cell3 {
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;

		.news-events-index-with-filters-excerpt {
			display: none;
		}
	}

	.news-events-index-with-filters-cardcell {
		width: 50%;
	}
}

@media (min-width: $wide) {
	.news-events-index-with-filters-cell1 {
		width: 50%;
	}

	.news-events-index-with-filters-cell2 {
		width: 50%;

		.news-events-index-with-filters-cardcell {
			width: 50%;
		}
	}

	.news-events-index-with-filters-cell3 {
		width: 100%;
	}

	.news-events-index-with-filters-cardcell {
		width: 25%;
	}
}

@media screen and (max-width: 480px) {
	.news-events-index-with-filters-title {
		text-align: center;
	}

	.news-events-index-with-filters-header form {
		flex-flow: column;
		.taxonomy-filter > .items-container {
			right: auto;
			left: 50%;
			transform: translateX(-50%);
			width: 100vw;
		}

		.taxonomy-filter + .taxonomy-filter {
			margin: 20px 0;
		}

		> .clear {
			margin: 10px 0 0 0;
		}
	}
}