footer.block-sitefooter {
	position: relative;
	background-color: $white;
}

.block-sitefooter-prefooter {
	font-size: 14px;
	line-height: 26px;
	padding: 32px 0 2px 0;
	> .container {
		max-width: 880px;
	}

	ul.menu {
		padding: 0;
		margin: 0;
		list-style: none;
		a {
			font-size: 14px;
			line-height: 26px;
			color: $footer-grey;
			&:hover {
				color: $hover-blue;
				text-decoration: none;
			}
		}

		ul.sub-menu ul.sub-menu {
			list-style-type: none;
			margin: 10px 0;
			padding: 0 0 0 24px;
			border-left: 1px solid $footer-grey;
			li {
				padding-bottom: 12px;
				a {
					display: block;
					line-height: 14px;
				}

				&:last-child {
					padding-bottom: 0;
				}
			}
		}

		> li {
			margin-bottom: 12px;
			> a {
				font-family: $font-bold;
			}
			> ul {
				list-style-type: none;
				padding: 0;
				margin: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.container-inner {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
}

.block-sitefooter-subfooter {
	color: $color-light;
	font-family: $font-medium;
	font-size: 10px;
	> .container-inner {
		max-width: 830px;
		padding: 0 20px;
		margin: 0 auto;
	}

	a {
		font-family: $font-medium;
		color: $blue;
		&:hover {
			color: $hover-blue;
		}
	}
}

.block-sitefooter-prefooter-widget-first {
	width: 50%;
	margin: auto 0;
	p {
		margin: 0;
	}
	
	img {
		max-width: 170px;
	}

	.social-block {
		margin-top: 45px;
		a {
			display: inline-block;
			width: 24px;
			height: 24px;
			margin-right: 15px;
			transition: filter 0.4s ease;
			&:last-child {
				margin-right: 0;
			}

			&:hover {
				filter: brightness(0);
			}

			&.facebook {
				background-image: url('blocks/sitefooter/facebook.svg');
			}
			&.twitter {
				background-image: url('blocks/sitefooter/twitter.svg');
			}
			&.linkedin {
				background-image: url('blocks/sitefooter/linkedin.svg');
			}
			&.instagram {
				background-image: url('blocks/sitefooter/instagram.svg');
			}
		}
	}
}

.block-sitefooter-prefooter-widget-second {
	margin: 0 auto 0 15px;
}

.block-sitefooter-subfooter-copyright {
	margin: 0;
	padding: 10px 0;
	color: $blue;
	font-family: $font-medium;
	font-size: 10px;
	line-height: 12px;
}

.block-sitefooter-subfooter-utilitylinks {
	margin: 0.25rem 1rem 0.25rem 0;
}

@media screen and (max-width: $fhd) {
	.block-sitefooter-prefooter > .container {
		max-width: 830px;
	}
}

@media screen and (max-width: $desktop) {
	.block-sitefooter-prefooter-widget-first {
		width: 25%;
	}

	.block-sitefooter-prefooter-widget-second {
		margin: auto;
	}
}

@media screen and (max-width: 600px) {
	.block-sitefooter-prefooter .container-inner {
		flex-direction: column;
	}

	.block-sitefooter-prefooter-widget-first {
		margin-bottom: 80px;
		text-align: center;
	}

	.block-sitefooter-prefooter-widget-first,
	.block-sitefooter-prefooter-widget-second,
	.block-sitefooter-prefooter-widget-third {
		width: 100%;
		padding: 0 30px;
	}

	.block-sitefooter-prefooter ul.menu > li {
		margin-bottom: 45px;
		a {
			font-size: 18px;
			line-height: 36px;
		}

		&:last-child {
			margin-bottom: 45px;
		}

	}

	.block-sitefooter-subfooter {
		padding: 0 30px;
		margin-top: 50px;
	}
}

@media screen and (max-width: 440px) {
	.block-sitefooter-subfooter {
		margin-top: 10px;
	}
}