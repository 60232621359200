.block-hero {
	background-color: $color-primary;
	background-image: url('/wp-content/themes/cscnew/blocks/hero/block-hero-landscape-dark.svg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	color: $color-dark;
	font-family: $font-title;
	padding: 7rem 0 5rem 0;
	position: relative;
	text-align: center;

	/*background:
	linear-gradient(rgba(80, 200, 232, 0), rgba(80, 200, 232, 1)),
	linear-gradient(-90deg, rgb(0, 200, 150), rgb(200, 200, 232));*/

	/*background:
		url('/wp-content/themes/cscnew/blocks/hero/block-hero-bg.svg'),
		url('/wp-content/themes/cscnew/blocks/hero/block-hero-bg.svg'),
		url('/wp-content/themes/cscnew/blocks/hero/block-hero-bg.svg'),
		linear-gradient(darken($color-primary, 10%), lighten($color-primary, 10%));
	background-position:
		center center;
	background-repeat:
		repeat,
		repeat,
		repeat,
		no-repeat;
	background-size:
		120%,
		60%,
		30%,
		cover;*/

	.container {
		position: relative;
	}
}

.block-hero-overlay {
	background: $color-light;
	bottom: 0;
	left: 0;
	opacity: 0.5;
	position: absolute;
	right: 0;
	top: 0;
}

.block-hero-image {

	margin: -1.5rem 0 2rem 0;

	img {
		height: auto;
		max-width: 100%;
	}
}

.block-hero-title {
	font-size: 3rem;
	font-weight: 400;
	line-height: 3rem;
	margin: 0 0 2rem 0;
}

.block-hero-subtitle {
	text-transform: uppercase;
	font-size: 1rem;
	font-weight: 400;
	margin: -1.5rem 0 2rem 0;
}

@media (min-width: $desktop) {
	.block-hero-overlay {
		display: none;
	}
}