/*!
Theme Name: cscnew
Author: East Coast Catalyst
Author URI: http://www.eastcoastcatalyst.com
Description: This is a base theme for ECC projects. It is meant to be extended for client sites.
Version: 9.1.5
*/

/*
|--------------------------------------------------------------------------
| Import global project styles.
|--------------------------------------------------------------------------
*/
@import "global/branding.scss";
@import "global/defaults.scss";

/*
|--------------------------------------------------------------------------
| Register block styles.
|--------------------------------------------------------------------------
*/
@import "blocks/button/block-button.scss";
@import "blocks/cta/block-cta.scss";
@import "blocks/divider/block-divider.scss";
@import "blocks/hero/block-hero.scss";
@import "blocks/iconlist/block-iconlist.scss";
@import "blocks/new/block-new.scss";
@import "blocks/pageheader/block-pageheader.scss";
@import "blocks/postcontent/block-postcontent.scss";
@import "blocks/relatedcontent_new/block-relatedcontent_new.scss";
@import "blocks/relatedcontent/block-relatedcontent.scss";
@import "blocks/sitefooter/block-sitefooter.scss";
@import "blocks/siteheader/block-siteheader.scss";
@import "blocks/spacer/block-spacer.scss";
@import "blocks/text/block-text.scss";
@import "blocks/texttwocol/block-texttwocol.scss";
@import "blocks/threeup/block-threeup.scss";
@import "blocks/zag/block-zag.scss";
@import "blocks/zig/block-zig.scss";
@import "custom-components/custom-blocks.scss";
@import "custom-components/news-events-filter.scss";

/*
|--------------------------------------------------------------------------
| Register template styles.
|--------------------------------------------------------------------------
*/
@import "templates/blocks/template-blocks.scss";
@import "templates/buyer/buyer.scss";
@import "templates/buyer/components/build-quote/_build-quote.scss";
@import "templates/buyer/components/contact/_contact.scss";
@import "templates/buyer/components/popups/_popup.scss";
@import "templates/buyer/components/service-rooms/_service-rooms.scss";
@import "templates/buyer/components/top-sort/_top-sort.scss";
@import "templates/history/template-history.scss";
@import "templates/home/template-home.scss";
@import "templates/ism/ism-landing.scss";
@import "templates/laundry/laundry.scss";
@import "templates/page/template-page.scss";
@import "templates/posts/template-posts.scss";