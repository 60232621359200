.block-postcontent-layout {
	max-width: 1000px;
	margin: 0 auto;
	padding: 0 15px;
	padding-bottom: 60px;
	padding-top: 60px;
}

.block-postcontent-layoutdouble {
	margin: 0 auto;
	overflow: auto;
	padding: 0 15px;
}

.block-postcontent-imagestage {}

.block-postcontent-bgimage {}

.block-postcontent-postimage {
	display: block;
	margin: 0 auto;
}

.block-postcontent-layoutright {
	padding-bottom: 60px;
	padding-top: 60px;
}

.block-postcontent-categorytags {
	color: $cool-grey;
	font-family: $font-medium;
	font-size: 14px;
	line-height: 28px;
	margin: 0 0 28px 0;
}

.block-postcontent-title {
	color: $blue;
	font-family: $font-medium;
	font-size: 40px;
	font-weight: normal;
	line-height: 48px;
	margin: 28px 0;
}

.block-postcontent-published {
	color: $soft-navy;
	font-family: $font-bold;
	font-size: 18px;
	line-height: 28px;
	margin: 28px 0 38px 0;
}

.block-postcontent-body {

	// These are the old WordPress WYSYWIG image floats.
	img.alignright {
		float: right;
		margin: 0 0 15px 15px;
	}

	img.alignleft {
		float: left;
		margin: 0 15px 15px 0;
	}

	img.aligncenter {
		display: block;
		margin: 30px auto;
	}
}

@media (min-width: $wide) {
	.block-postcontent-layoutdouble {
		position: relative;
		display: flex;
		flex-direction: row;
		padding: 0;
	}

	.block-postcontent-layoutleft {
		width: 45%;
	}

	.block-postcontent-imagestage {
		display: block;
		height: 100vh;

		.block-postcontent-bgimage {
			background-position: center center;
			background-size: cover;
			height: 100%;
			position: fixed;
			top: 0;
			left: 0;
			width: 45%;
		}

		.block-postcontent-postimage {
			display: none;
			position: absolute;
			width: 45%;
			top: 0;
			left: 0;
			z-index: -1;
			&.fixed {
				position: fixed;
				&:not(.bottom) {
					max-width: 45%;
				}
			}

			&.bottom {
				position: absolute;
				bottom: 0;
				top: auto;
				& + .block-postcontent-bgimage {
					position: absolute;
					height: 100vh;
					bottom: 0;
					top: auto;
					width: 45%;
				}
			}

			&.no-sticky + .block-postcontent-bgimage {
				position: absolute;
				height: 100%;
			}
		}
	}

	.block-postcontent-imagestagecover {
		display: block;
    	height: 100vh;

		.block-postcontent-bgimage {
			background-position: center center;
			background-size: cover;
			width: 100%;
			height: 100%;
			position: fixed;
			top: 0;
			left: 0;
			width: 45%;

			opacity: 0.3;
			-webkit-filter: blur(8px);
			-moz-filter: blur(8px);
			-o-filter: blur(8px);
			-ms-filter: blur(8px);
			filter: blur(8px);
		}

		.block-postcontent-postimage {
			position: fixed;
			box-sizing: border-box;
			z-index: 4;
			left: 0;
			top: 0;
			padding: calc(99px + 5%) 5% 0;
			width: 45%;
			&.bottom + .block-postcontent-bgimage {
				position: absolute;
				height: 100vh;
				bottom: 0;
				top: auto;
				width: 45%;
			}

			&.img-bottom {
				position: absolute;
				bottom: 0;
				top: auto;
			}

			&.no-sticky {
				position: absolute;
				padding: 5% 5% 0;
				& + .block-postcontent-bgimage {
					position: absolute;
					height: 100%;
				}
			}
		}

		&:after {
			content: "";
			background: $white;
			width: 3%;
			height: 100%;
			position: absolute;
			left: 44%;
			top: 0;
			z-index: 2;
		}
	}

	.block-postcontent-layoutright {
		padding-left: 5%;
		padding-right: 5%;
		width: 55%;
	}
}

.single-post-main {
	> .container {
		max-width: 1176px;
		padding: 0 24px;
	}
	
    .top-section {
        padding: 80px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .data {
            width: calc(50% - 12px);
            h1 {
                font-size: 40px;
                line-height: 48px;
                margin: 0 0 16px;
            }

            p {
                line-height: 24px;
                margin: 0;
            }

            .date {
                font-size: 18px;
                line-height: 22px;
                color: $deep-navy;
                font-family: $font-bold;
                margin: 0 0 16px;
            }
        }

        .post-image {
            display: block;
            width: calc(50% - 12px);
            height: auto;
        }
    }

    .body {
        max-width: 935px;
        margin: 0 auto 80px;
        p {
            clear: left;
            color: #61666A;
            font-size: 16px;
            line-height: 24px;
            a {
                color: #61666A;
                font-size: 16px;
                line-height: 24px;
                text-decoration: underline;
                &:hover {
                    color: $deep-navy;
                }
            }

            img {
                margin-top: 56px;
                margin-bottom: 56px;
                &.alignleft {
                    margin-right: 24px;
                }
                &.aligncenter {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
                &.alignright {
                    margin-left: 24px;
                }
            }

            // &:first-child {
            //     font-size: 20px;
            //     line-height: 32px;
            //     a {
            //         font-size: 20px;
            //         line-height: 32px;
            //     }
            // }

            &:not(:last-child) {
                margin: 0 0 32px;
            }

            & + h2,
            & + h3,
            & + h4,
            & + h5, 
            & + h6 {
                margin-top: 24px;
            }
        }

        ul, ol {
            margin: 0 0 56px;
            padding: 0 0 0 20px;
            li {
                color: #61666A;
                font-size: 16px;
                line-height: 24px;
                margin: 0 0 10px;
            }
        }

        h2, h3, h4, h5, h6 {
            float: left;
            clear: left;
            color: $deep-navy;
            margin: 0 0 32px;
        }

		h4 {
			font-family: $font-body;
		}

        h6 {
            font-size: 24px;
            line-height: 32px;
            font-family: $font-body;
            font-weight: 400;
        }

		.alignright {
			float: right;
		}

		.alignleft {
			float: left;
		}
    }

    .imagery { 
        padding: 80px 0;
        & > .container {
            display: grid;
            grid-template-columns: repeat(3 , 1fr);
            grid-gap: 24px;
			max-width: 1176px;
			padding: 0 24px;
            .item {
                display: flex;
                align-items: flex-start;
                flex-flow: column;
				h5 {
					font-family: $font-bold;
					font-weight: 600;
					font-size: 18px;
					line-height: 24px;
					color: $navy;
					margin: 0 0 8px;
				}

                img {
                    margin: 0 0 8px;
                    width: auto;
                    height: 80px;
                }

                p {
                    font-size: 14px;
                    line-height: 20px;
                    color: #445463;
                }
            }
        }

        @media screen and (min-width: 481px) and (max-width: 768px) {
            & > .container {
                grid-template-columns: repeat(2 , 1fr);
            }
        }

        @media screen and (max-width: 480px) {
            padding: 40px 0 32px;
            & > .container {
                grid-template-columns: repeat(1 , 100%);
                .item {
                    h5 {
                        font-size: 18px;
                        line-height: 26px;
                    }
                }
            }
        }
    }

    .post-actions {
        padding: 24px 0;
        background-color: $deep-navy;
        > .container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
			max-width: 1176px;
			padding: 0 24px;
            h4 {
                display: flex;
                align-items: center;
                margin: 0 auto 0 0;
				background-image: url('blocks/postcontent/post-download.png');
                background-repeat: no-repeat;
                background-position: left center;
                background-size: 72px;
                min-height: 72px;
                padding-left: 88px;

				font-family: $font-body;
				font-weight: 400;
				font-size: 24px;
				line-height: 32px;
				color: $white;
            }

            .cta-area {
                display: flex;
				.post-button {
					position: relative;
					display: inline-block;
					background: linear-gradient(to top, $green 50%, $hover-blue 50%);
					background-size: 100% 200%;
					background-position: center top;
					background-repeat: no-repeat;
					border-radius: 18px;
					color: $white;
					padding: 0 40px;
					font-size: 14px;
					line-height: 36px;
					font-family: $font-bold;
					max-width: 100%;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					box-sizing: border-box;
					transition: background 0.4s ease;
					box-shadow: 0px 3px 6px rgba(0, 0, 0, .25);
					border: none;
					cursor: pointer;
					display: table;
					text-decoration: none;
					text-align: center;
				
					&:hover {
						text-decoration: none;
						color: #FFF;
						background-position: center bottom;
						text-decoration: none;
					}
				}

                a:not(:last-child) {
                    margin: 0 24px 0 0;
                }
            }

            @media screen and (max-width: 768px) {
                flex-flow: column;
                h4 {
                    margin: 0 0 24px;
                }
            }

            @media screen and (max-width: 480px) {
                h4 {
                    font-size: 16px;
                    line-height: 24px;
                    padding-left: 100px;
                }

                .cta-area {
                    flex-flow: column;
                    align-items: center;
                    a:not(:last-child) {
                        margin: 0 0 24px 0;
                    }
                }
            }
        }
    }

    .related {
        padding: 40px 0 70px;
        background: linear-gradient(to bottom, #EAF6FC 50%, $white 50%, $white);
		> .container {	
			max-width: 1176px;
			padding: 0 24px;
		}

        .header {
            text-align: center;
            margin: 0 0 30px;
        }

        .wrapper {
            display: flex;
            align-items: flex-start;
        }
    }

    @media screen and (max-width: 990px) {
        .wrapper {
            flex-wrap: wrap;
            justify-content: center;
            .teaser-card {
                flex: auto;
                width: 50%;
                margin: 0 12px 24px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .top-section {
            flex-flow: column;
            padding: 24px 0 40px;
            .data {
                width: 100%;
                margin: 0 0 24px;
            }

            .post-image {
                width: 100%;
            }
        }

        .body {
            margin: 0 0 56px;
        }
    }

    @media screen and (max-width: 480px) {
        .top-section {
            .data p {
                font-size: 14px;
                line-height: 20px;
            }
            .post-image {
                width: calc(100% + 48px);
                max-width: unset;
            }
        }

        .body {
            p {
                font-size: 14px;
                line-height: 20px;
                margin: 0 0 24px;
                &:first-child {
                    font-size: 16px;
                    line-height: 24px;
                }

                & + h2,
                & + h3,
                & + h4,
                & + h5, 
                & + h6 {
                    margin-top: 0;
                }
            }
        }
    }
}