/* Page container overwrites */
.page-template-ism-landing #page,
.page-template-ism-landing #main {
    width: 100%;
    margin: 0;
}

.page-template-ism .block-siteheader-menutoggle,
.page-template-ism .block-siteheader-nav.block-siteheader-nav-hidden {
    display: none!important;
}

.page-template-ism .block-siteheader-logolink {
    padding: 17px 0;
}

.page-template-ism #menu-item-1793 {
    display: none;
}

#tag-line-banner h3 {
    color: #FFF;
}

#footer-bg-top-area {
    margin-top: 0;
}

/* General */
.container {
    max-width: 1708px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

p {
    font-family: $font-body;
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    margin: 0 0 30px;
    color: #707070;
}

h1, .h1 {
    font-size: 50px;
    line-height: 60px;
    font-family: $font-medium;
    color: #142A65;
    margin: 0 0 42px;
    padding: 0;
    font-weight: 600;
}

h2, .h2 {
    font-size: 36px;
    line-height: 44px;
    font-family: $font-medium;
    font-weight: 600;
    color: #142A65;
    margin: 0 0 40px;
}

h3, .h3 {
    font-size: 28px;
    line-height: 34px;
    color: #142A65;
    font-family: $font-medium;
    font-weight: 600;
    margin: 0;
    text-transform: none;
}

h4, .h4 {
    font-size: 24px;
    line-height: 36px;
    color: #42B9F8;
    font-family: $font-medium;
    font-weight: 600;
    margin: 0;
}

/* ISM Hero */
#ism-hero {
    position: relative;
    height: 600px;
    background-image: url('../csc-new/templates/ism/images/ISM-Hero-Image.jpg');
    background-size: cover;
    background-position: right center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
}

#ism-hero .container {
    padding-right: 50%;
}

#ism-hero h1 {
    text-transform: uppercase;
}

/* ISM Text CTA */
#ism-text-cta {
    background: #EAFAFF;
    padding: 60px 0;
    background-image: url('../csc-new/templates/ism/images/CSC-Outlines-Triangles-Dark.svg');
    background-repeat: no-repeat;
    background-position: right -200px center;
    background-size: auto 200%;
}

#ism-text-cta .container {
    max-width: 1100px;
}

#ism-text-cta .container .button {
    display: table;
    margin: 57px auto 0;
}

/* ISM Fifty */
#ism-fifty {
    display: flex;
}

#ism-fifty > * {
    width: 50%;
    box-sizing: border-box;
}

#ism-fifty .body {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    background-color: rgba(242, 242, 242, 0.56);
    padding: 60px 20px 0;
}

#ism-fifty .body h2 {
    margin: 0 0 40px;
}

#ism-fifty .body > * {
    width: 100%;
    max-width: 800px;
}

#ism-fifty .image {
    background-image: url('../csc-new/templates/ism/images/Kindig_CSC30014.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* ISM 2 UP */
#ism-two-up {
    padding: 60px 0;
    text-align: center;
}

#ism-two-up .container {
    max-width: 1490px;
}

#ism-two-up .ism-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 90px auto 0;
}

#ism-two-up .ism-wrapper .item {
    width: 20%;
    max-width: 230px;
}

#ism-two-up .ism-wrapper .item .image {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto 34px;
    background: #3FB0F6;
    border-radius: 100%;
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
}

#ism-two-up .ism-wrapper .item:first-child .image {
    background-image: url('../csc-new/templates/ism/images/Laundromat\ Icon.svg');
}
#ism-two-up .ism-wrapper .item:nth-child(2) .image {
    background-image: url('../csc-new/templates/ism/images/Piggy\ Bank\ Icon.svg');
}
#ism-two-up .ism-wrapper .item:nth-child(3) .image {
    background-image: url('../csc-new/templates/ism/images/Support\ Icon.svg');
}
#ism-two-up .ism-wrapper .item:last-child .image {
    background-image: url('../csc-new/templates/ism/images/Increase\ Resident\ Satisfaction\ Icon.svg');
}

/* ISM Just Title */
#ism-just-title {
    padding: 73px 0;
    text-align: center;
}

#ism-just-title p.h1 {
    margin: 0;
}

/* ISM Fifty Form */
#ism-fifty-form {
    display: flex;
}
    
#ism-fifty-form > * {
    width: 50%;
    box-sizing: border-box;
}

#ism-fifty-form .description {
    background-color: rgba(242, 242, 242, 0.56);
    padding: 104px 64px 104px 20px;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
}

#ism-fifty-form .description > * {
    width: 100%;
    max-width: 756px;
}

#ism-fifty-form .description .logo {
    text-align: center;
    margin: 0 0 164px;
}

#ism-fifty-form .description .logo img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
}

#ism-fifty-form .description h2 {
    color: #02BCF4;
    font-size: 42px;
    line-height: 60px;
    margin: 0 0 60px;
}

#ism-fifty-form .description .cta-like {
    position: relative;
    background: #283E7A;
    border-radius: 100px;
    color: #FFF;
    font-size: 28px;
    line-height: 40px;
    padding: 40px 215px 36px 80px;
    font-family: $font-bold;
    margin: 170px auto 0;
    box-sizing: border-box;
}

#ism-fifty-form .description .cta-like:after {
    content: "";
    position: absolute;
    right: 58px;
    bottom: 50%;
    width: 72px;
    height: 70px;
    background-image: url('../csc-new/templates/ism/images/arrow-right.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(50%);
}

#ism-fifty-form .form {
    background-color: #EAFAFF;
    padding: 80px 20px 60px;
}

#ism-fifty-form .form img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
}

// FORM
#ism-fifty-form {
    display: flex;
}
    
#ism-fifty-form > * {
    width: 50%;
    box-sizing: border-box;
}

#ism-fifty-form .description {
    background-color: rgba(242, 242, 242, 0.56);
    padding: 104px 64px 104px 20px;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
}

#ism-fifty-form .description > * {
    width: 100%;
    max-width: 756px;
}

#ism-fifty-form .description .logo {
    text-align: center;
    margin: 0 0 164px;
}

#ism-fifty-form .description .logo img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
}

#ism-fifty-form .description h2 {
    color: #02BCF4;
    font-size: 42px;
    line-height: 60px;
    margin: 0 0 60px;
}

#ism-fifty-form .description .cta-like {
    position: relative;
    background: #283E7A;
    border-radius: 100px;
    color: #FFF;
    font-size: 28px;
    line-height: 40px;
    padding: 40px 215px 36px 80px;
    font-family: $font-bold;
    margin: 170px auto 0;
    box-sizing: border-box;
}

#ism-fifty-form .description .cta-like:after {
    content: "";
    position: absolute;
    right: 58px;
    bottom: 50%;
    width: 72px;
    height: 70px;
    background-image: url('../csc-new/templates/ism/images/arrow-right.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(50%);
}

#ism-fifty-form .form {
    background-color: #EAFAFF;
    padding: 80px 60px 60px;
}

#ism-fifty-form .form .uppercase {
    text-transform: uppercase;
}

@media screen and (min-width: 600px) {
    #ism-fifty-form .form .flex {
        align-items: center;
        display: flex;
    }
}


@media screen and (min-width: 600px) {
    #ism-fifty-form .form .half {
        flex: 1 1 50%;
    }

    #ism-fifty-form .form .left {
        padding-right: 15px;
    }

    #ism-fifty-form .form .right {
        padding-left: 15px;
    }
}

#ism-fifty-form .form img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
}

#ism-fifty-form .form .screenreader {
    position: absolute !important; /* Outside the DOM flow */
    height: 1px; width: 1px; /* Nearly collapsed */
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
    clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}

#ism-fifty-form .form input,
#ism-fifty-form .form select {
    width: 100%;
}

#ism-fifty-form .form input {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #C9D4D8;
    border-radius: 0;
    box-sizing: border-box;
    color: #7A7D7D;
    font-family: $font-body;
    font-size: 18px;
    margin-top: 30px;
    padding: 15px 40px;
}

#ism-fifty-form .form input:focus {
    outline: none;
    border-color: #6BC546;
}

#ism-fifty-form .form input[type=checkbox] {
    position: absolute;
    margin: 0;
    opacity: 0;
    left: 46px;
    top: 64px;
    width: 10px;
    height: 10px;
}

#ism-fifty-form .form input[type=checkbox] + label {
    color: #7A7D7D;
    clip: auto;
    display: inline-block;
    font-family: $font-body;
    font-size: 18px;
    height: auto;
    line-height: 1.6;
    overflow: auto;
    margin: 50px 40px;
    padding-left: 35px;
    position: relative !important;
    width: auto;
    cursor: pointer;
}

#ism-fifty-form .form input[type=checkbox] + label:before {
    background-color: #fff;
    border: 1px solid #7A7D7D;
    content: "";
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 20px;
}

#ism-fifty-form .form input[type=checkbox] + label:after {
    position: absolute;
    background-color: #6BC546;
    content: "";
    display: block;
    height: 0;
    left: 1px;
    top: 3px;
    width: 0;
}

#ism-fifty-form .form input[type=checkbox]:checked + label:after {
    animation: createBox .25s;
    height: 20px;
    width: 20px;
}

@keyframes createBox {
    from {
        transform: scale(0);
    } to {
        transform: scale(1);
    }
}

#ism-fifty-form .form .custom-select {
    position: relative;
}

#ism-fifty-form .form .custom-select select {
    position: absolute;
    bottom: 0px;
    left: 150px;
    z-index: -1;
    width: 10px;
    height: 10px;
    opacity: 0;
}

#ism-fifty-form .form .custom-select .select-selected:after {
  position: absolute;
  content: "";
  bottom: 10px;
  right: 15px;
  width: 0;
  height: 0;
  border: 10px solid #6BC546;
  border-color: #6BC546 transparent transparent transparent;
}

@media screen and (min-width: 600px) {
    #ism-fifty-form .form .custom-select .select-selected:after {
        right: 30px;
    }
}

/* Point the arrow upwards when the select box is open (active): */
#ism-fifty-form .form .custom-select .select-selected.select-arrow-active:after {
  border-color: transparent transparent #6BC546 transparent;
  bottom: 20px;
}

/* style the items (options), including the selected item: */
#ism-fifty-form .form .custom-select .select-items div,.select-selected {
  color: #7A7D7D;
  padding: 15px 40px;
  border: 1px solid #C9D4D8;
  cursor: pointer;
  font-family: $font-body;
  font-size: 18px;
  margin-top: 30px;
  min-height: 54px;
}

/* Style items (options): */
#ism-fifty-form .form .custom-select .select-items {
  position: absolute;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  max-height: 230px;
  overflow-y: scroll;
}

@media screen and (min-width: 600px) {
    #ism-fifty-form .form .custom-select .select-items {
        margin-right: 15px;
    }
}

#ism-fifty-form .form .custom-select .select-items div {
    font-size: 14px;
    margin: 0;
}

/* Hide the items when the select box is closed: */
#ism-fifty-form .form .custom-select .select-hide {
  display: none;
}

#ism-fifty-form .form .custom-select .select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

#ism-fifty-form .form-footer {
    margin: 0 auto;
}

/* Responsive */
@media screen and (min-width: 990px) and (max-width: 1440px) {
    #ism-fifty-form .description .cta-like {
        padding: 40px 50px 100px 40px;
    }

    #ism-fifty-form .description .cta-like:after {
        right: 50%;
        bottom: 20px;
        transform: translateX(50%);
    }
}

@media screen and (max-width: 1280px) {
    #ism-two-up .ism-wrapper .item {
        width: 25%;
        max-width: unset;
        padding: 0 10px;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 990px) {
    #ism-hero {
        align-items: flex-end;
    }
    
    #ism-hero .container {
        padding: 20vw 20px 80px;
    }

    #ism-hero .container > * {
        position: relative;
        z-index: 1;
    }

    #ism-hero .container:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: linear-gradient(to top, #FFF, transparent);
    }
    
    #ism-two-up .ism-wrapper {
        flex-wrap: wrap;
    }

    #ism-two-up .ism-wrapper .item {
        width: 50%;
        margin: 0 0 40px 0;
    }

    #ism-fifty-form {
        flex-flow: column;
    }

    #ism-fifty-form > * {
        width: 100%;
    }

    #ism-fifty-form .form {
        padding: 40px 20px 60px;
    }

    #ism-fifty-form .description {
        padding: 60px 20px;
    }

    #ism-fifty-form .description .logo {
        margin: 0 auto 60px;
    }

    #ism-fifty-form .description h2 {
        margin: 0 0 40px;
    }

    #ism-fifty-form .description .cta-like {
        font-size: 24px;
        line-height: 30px;
        padding: 30px 30px 120px 30px;
        margin: 60px auto 0;
        border-radius: 40px;
        text-align: center;
    }
    #ism-fifty-form .description .cta-like:after {
        right: 50%;
        bottom: 20px;
        transform-origin: right center;
        transform: rotate(90deg) translateX(50%);
    }
}

@media screen and (max-width: 768px) {
    #ism-fifty {
        flex-flow: column;
    }

    #ism-fifty > * {
        width: 100%;
    }

    #ism-fifty .image {
        height: 100vw;
    }
}

@media screen and (max-width: 540px) {
    h1, .h1 {
        font-size: 40px;
        line-height: 50px;
    }

    h2, .h2 {
        font-size: 32px;
        line-height: 40px;
    }

    #ism-hero .container:before {
        background: linear-gradient(to top, #FFF 40%, transparent);
    }

    #ism-two-up .ism-wrapper .item {
        width: 100%;
    }
    
    #ism-two-up .ism-wrapper .item:last-child {
        margin: 0;
    }

    #ism-fifty-form .form input[type=checkbox] + label {
        margin: 50px 0;
    }
}

// ISM request form
form.ism-form .form-row,
form.ism-form .form-row .half {
    position: relative;
}

form.ism-form .form-row label:first-child {
    position: absolute;    
    left: 41px;
    bottom: 17px;
    transition: all 0.3s ease;
    font-size: 16px;
}

form.ism-form .form-row.on label:first-child,
form.ism-form .form-row .half.on label:first-child {
    left: 20px;
    bottom: 58px;
    font-size: 12px;
}