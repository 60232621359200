.block-threeup {
	margin-top: 3rem;
}

.block-threeup-column-layout {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin: 3rem 0;
}

.block-threeup-image {
	width: 100%;

	img {
		height: auto;
		width: 100%;
	}
}

.block-threeup-body {
	font-family: $font-body;
	font-size: 0.9rem;
	line-height: 1.4rem;
	text-align: center;

	ul,
	ol {
		text-align: left;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $font-title;
		line-height: 1.6rem;
	}
}

@media (min-width: $tablet) {
	.block-threeup-image {
		padding-left: 16.665%;
		padding-right: 16.665%;
	}

	.block-threeup-body {
		padding-left: 16.665%;
		padding-right: 16.665%;
	}
}

@media (min-width: $desktop) {
	.block-threeup-layout {
		display: flex;
		flex-direction: row;
	}

	.block-threeup-column-layout {
		margin-left: 1.665%;
		margin-right: 1.665%;
		width: 30%;
	}

	.block-threeup-image {
		padding-left: 0;
		padding-right: 0;
	}

	.block-threeup-body {
		flex-grow: 1;
		padding-left: 0;
		padding-right: 0;
	}
}