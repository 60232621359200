.block-relatedcontent {
	background: linear-gradient(to bottom, $mobile-blue 0%, $mobile-blue 50%, $white 50%, $white 100%);
	margin: 0;
}

.block-relatedcontent-layout {
	margin: 0 auto;
	max-width: 1400px;
	overflow: auto;
	padding: 0 15px 1px 15px;
}

.block-relatedcontent-title {
	color: $blue;
	font-family: $font-medium;
	font-size: 20px;
	line-height: 24px;
	margin: 29px 0;
	text-align: center;
}

.block-relatedcontent-cards {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.block-relatedcontent-cardlayout {
	background-color: $white;
	box-shadow: 0 3px 6px #00000020;
	box-sizing: border-box;
	display: block;
	margin-bottom: 15px;
	padding: 12px;
	text-decoration: none;
	transition: box-shadow 0.25s, transform 0.25s;

	&:focus,
	&:hover {
		box-shadow: 0 8px 12px #00000010;
		text-decoration: none;
		transform: translateY(-5px);
	}
}

.block-relatedcontent-cardinner {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.block-relatedcontent-cardimage {
	background-position: center center;
	background-size: cover;
	padding-bottom: 50%; // This is a 2:1 aspect ratio div.
	position: relative;
	width: 100%;
}

.block-relatedcontent-cardlabel {
	background-color: $white;
	color: $footer-grey;
	font-family: $font-bold;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	margin: 0;
	padding: 3px 6px;

	position: absolute;
	bottom: 0;
	left: 6px;
}

.block-relatedcontent-cardtitle {
	color: $blue;
	font-family: $font-medium;
	font-size: 20px;
	font-weight: normal;
	line-height: 24px;
	margin: 0;
	padding: 12px;
}

.block-relatedcontent-cardtags {
	color: $cool-grey;
	font-family: $font-body;
	font-size: 14px;
	line-height: 14px;
	margin: 0;
	padding: 0 12px 12px 12px;
}

.block-relatedcontent-dateline {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: auto;
	padding: 12px 12px 0 12px;
	width: 100%;
}

.block-relatedcontent-readmore {
	color: $light-blue;
	font-family: $font-body;
	font-size: 14px;
	line-height: 14px;
}

.block-relatedcontent-published {
	color: $soft-navy;
    font-size: 14px;
    font-weight: normal;
    line-height: 28px;
    margin: 0;
    padding: 15px 12px 0;
}

@media (min-width: $desktop) {
	.block-relatedcontent-cards {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.block-relatedcontent-cardlayout {
		width: calc(50% - 10px);
	}
}

@media (min-width: $wide) {
	.block-relatedcontent-cardlayout {
		width: calc(25% - 10px);
	}
}